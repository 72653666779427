import { gql } from '@apollo/client';

const GET_USERS = gql`
  {
    userList {
      id
      username
      email
      password
      link
      userLink {
        id
        title
        url
        isActive
      }
      theme {
        title
        backgroundStyle
        buttonStyle
        fontStyle
      }
      isActive
      isCustom
      profileImage
    }
  }
`;

const USER_LOGIN = gql`
  query loginUser($username: String!, $password: String!) {
    loginUser(data: { username: $username, password: $password }) {
      id
      username
      email
      token
      roles
      link
      profileImage
      page {
        id
        backgroundId
        isActive
        background {
          id
          backgroundStyle
          backgroundImage
        }
      }
      userLink {
        id
        title
        value
        type
        isActive
      }
      theme {
        title
        backgroundStyle
        buttonStyle
        fontStyle
      }
      isActive
      isCustom
    }
  }
`;

const GET_CURRENT_USER = gql`
  {
    findUser {
      id
      username
      email
      link
      profileImage
      userLink {
        id
        title
        value
        type
        isActive
      }
      theme {
        id
        title
        backgroundStyle
        buttonStyle
        fontStyle
      }
      roles
      isActive
      isCustom
      page {
        id
        backgroundId
        isActive
        background {
          id
          backgroundStyle
          backgroundImage
        }
      }
    }
  }
`;

const FORGET_PASSWORD = gql`
  query forgetpwd($email: String!) {
    forgotPassword(data: { email: $email })
  }
`;

export default GET_USERS;
export { USER_LOGIN, FORGET_PASSWORD, GET_CURRENT_USER };
