import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Layout, message, Popconfirm, Row } from 'antd';
import Meta from 'antd/lib/card/Meta';
import defaultBg from '../../assets/images/defaultBg.jpg';
import { GET_THEMES } from '../user/graphql/Query';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import ThemeModal from '../../components/ThemeModal/ThemeModal';
import { DELETE_THEME } from './graphql/mutation';

const { Content } = Layout;

const Theme = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editThemeData, setEditThemeData] = useState('');
  const { data } = useQuery(GET_THEMES, {
    fetchPolicy: 'network-only'
  });
  // delete theme
  const [deleteTheme] = useMutation(DELETE_THEME, {
    onCompleted() {
      message.success('Theme deleted successfully');
    }
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const editThemeDataHandler = (updatedThemeData) => {
    setEditThemeData(updatedThemeData);
  };

  const editThemeBtnHandler = (themeId) => {
    showModal();
    const findEditThemeData = data.themeList.find(
      (theme) => theme.id === themeId
    );
    if (findEditThemeData) setEditThemeData(findEditThemeData);
  };

  const deleteThemeHandler = (themeId) => {
    deleteTheme({
      variables: {
        id: themeId
      },
      refetchQueries: [{ query: GET_THEMES }]
    });
  };

  return (
    <Content>
      <Row justify="center" className="mb-20">
        <Col span={11} className="mr-10">
          <Button
            block
            type="primary"
            className="btnPrimary"
            onClick={showModal}
          >
            Add Theme
          </Button>
        </Col>
      </Row>
      <Row justify="center" align="middle" gutter={[16, 16]}>
        {data &&
          data.themeList &&
          data.themeList.map((theme) => {
            return (
              <Col key={theme.id}>
                <Card
                  hoverable
                  className="appearance-card hover-card"
                  cover={
                    theme?.image ? (
                      <>
                        <img
                          className="img-blur"
                          height="250px"
                          alt={theme?.title}
                          src={theme?.image || defaultBg}
                        />
                        <Row>
                          <Col className="hover-Edit">
                            <Button
                              className="iconBtn"
                              onClick={() => editThemeBtnHandler(theme.id)}
                              icon={
                                <EditOutlined
                                  style={{ fontSize: '18px', color: '#030846' }}
                                />
                              }
                            />
                          </Col>
                          <Col className="hover-delete">
                            <Popconfirm
                              title="Are you sure you want to delete theme?"
                              onConfirm={() => deleteThemeHandler(theme.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                className="iconBtn"
                                icon={
                                  <DeleteOutlined className="delete_icon" />
                                }
                              />
                            </Popconfirm>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <div
                        className="themeCards"
                        style={theme?.backgroundStyle}
                      >
                        <Row>
                          <Col className="hover-Edit">
                            <Button
                              className="iconBtn"
                              onClick={() => editThemeBtnHandler(theme.id)}
                              icon={
                                <EditOutlined
                                  style={{ fontSize: '18px', color: '#030846' }}
                                />
                              }
                            />
                          </Col>
                          <Col className="hover-delete">
                            <Popconfirm
                              title="Are you sure you want to delete theme?"
                              onConfirm={() => deleteThemeHandler(theme.id)}
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                className="iconBtn"
                                icon={
                                  <DeleteOutlined
                                    style={{
                                      fontSize: '18px',
                                      color: '#E94A4A'
                                    }}
                                  />
                                }
                              />
                            </Popconfirm>
                          </Col>
                        </Row>
                      </div>
                    )
                  }
                >
                  <Meta title={theme?.title || '-'} />
                </Card>
              </Col>
            );
          })}
      </Row>
      <ThemeModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        editThemeData={editThemeData}
        editThemeDataHandler={editThemeDataHandler}
      />
    </Content>
  );
};
export default Theme;
