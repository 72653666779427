import React, { useState, useEffect, useContext } from 'react';
import { Layout, Form, Col, Typography, message, Row, Popconfirm } from 'antd';
import { useHistory } from 'react-router-dom';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import client from '../../apollo';
import GET_USERS from '../auth/graphql/Query';
import { UPDATE_USER } from './graphql/Mutation';
import ChangePwd from '../auth/ChangePwd';
import Card from '../../components/Card/card';
import Input from '../../components/Input/input';
import Button from '../../components/Button/Button';
import { REGEX, ROUTES } from '../../common/constant';
import { AppContext } from '../../AppContext';

const { Content } = Layout;
const { Title } = Typography;

function UserProfile() {
  const [show, setShow] = useState(false);
  // for username
  const [oldUsername, setOldUsername] = useState('');
  const [newUsername, setNewUsername] = useState('');

  // for email
  const [oldEmail, setOldEmail] = useState('');
  const [newEmail, setNewEmail] = useState('');

  const { dispatch, state } = useContext(AppContext);
  const currentUser = state?.userDetails;

  const history = useHistory();

  useEffect(() => {
    if (currentUser) {
      setOldUsername(currentUser?.username);
      setOldEmail(currentUser?.email);
      const isPro = currentUser?.isCustom;
      if (isPro === true) {
        setShow(false);
      } else {
        setShow(true);
      }
    }
  }, [currentUser]);

  const onFinish = () => {
    const updatedLink = `${process.env.REACT_APP_CLIENT_URL}/${
      newUsername || oldUsername
    }`;

    if (!(newUsername || oldUsername)) {
      return message.error('Please enter valid username');
    }
    if (!REGEX.EMAIL.test(newEmail || oldEmail)) {
      return message.error('Please enter valid email');
    }
    setNewEmail('');
    setNewUsername('');

    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id: currentUser?.id,
          link: updatedLink,
          username: newUsername || oldUsername,
          email: newEmail || oldEmail
        },
        refetchQueries: GET_USERS
      })
      .then(() => {
        message.success('Your information is updated please login again!');
        dispatch({ type: 'LOGOUT' });
        history.push(ROUTES.LOGIN);
      })
      .catch(() => {});
  };

  const onFinishFailed = (errorInfo) => {
    message.error(errorInfo);
  };

  const usernameHandler = (e) => {
    setNewUsername(e?.target?.value);
  };
  const emailHandler = (e) => {
    setNewEmail(e?.target?.value);
  };

  const getPopoverTitle = () => {
    let title;
    const suffix = 'The changes will be irreversible.';

    if (newUsername && newEmail) {
      title = `Are you sure you want to change your username and email. ${suffix}`;
    } else if (newUsername) {
      title = `Are you sure you want to change your username. ${suffix}`;
    } else if (newEmail) {
      title = `Are you sure you want to change your email. ${suffix}`;
    }
    return title;
  };

  const getBtnIsDisable = () => {
    let isDisable = false;
    // user only change username
    if (newUsername === oldUsername && !newEmail.length) isDisable = true;

    // user only change email
    if (oldEmail === newEmail && !newUsername.length) isDisable = true;

    // user change both username and email
    if (newUsername === oldUsername && newEmail === oldEmail) isDisable = true;

    // user not change anything
    if (!(newEmail.length || newUsername.length)) isDisable = true;

    return isDisable;
  };

  return (
    <Content className="layout mt-36">
      <Row justify="center" gutter={{ xs: 3, sm: 16, md: 24, lg: 32 }}>
        <Title level={4}>My Profile</Title>
      </Row>
      <Row justify="center">
        <Col>
          <Card title="Personal Details" className="mt-20 link-card">
            {currentUser && (
              <Form
                name="basic"
                initialValues={currentUser}
                onFinishFailed={onFinishFailed}
                layout="vertical"
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username!'
                    }
                  ]}
                >
                  <Input
                    addonBefore="Username"
                    suffix={<UserOutlined className="site-form-item-icon" />}
                    disabled={show}
                    onChange={usernameHandler}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      message: 'Please input your email!',
                      required: true
                    }
                  ]}
                >
                  <Input
                    type="email"
                    addonBefore="Email"
                    suffix={<MailOutlined className="color-grey" />}
                    onChange={emailHandler}
                  />
                </Form.Item>
                <Form.Item className="text-right mb-0">
                  <Popconfirm
                    placement="bottom"
                    title={getPopoverTitle()}
                    onConfirm={onFinish}
                    okText="Confirm"
                    cancelText="Cancel"
                    disabled={getBtnIsDisable()}
                  >
                    <Button
                      className="btnPrimary"
                      type="primary"
                      shape="round"
                      size="small"
                      htmlType="submit"
                      disabled={getBtnIsDisable()}
                    >
                      Save
                    </Button>
                  </Popconfirm>
                </Form.Item>
              </Form>
            )}
          </Card>
          <Card title="Account Details" className="mt-20 link-card">
            <Form name="basic" size="middle">
              <Form.Item className="mb-0">
                <Row align="middle" justify="space-between">
                  <Col>
                    <span className="ant-form-text">Free</span>
                  </Col>
                  <Col>
                    <Button type="default" size="small" shape="round">
                      Buy a Pro
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </Card>
          <Card title="Change password" className="mt-20 mb-36 link-card">
            <ChangePwd />
          </Card>
        </Col>
      </Row>
    </Content>
  );
}
export default UserProfile;
