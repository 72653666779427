import { gql } from '@apollo/client';

const ADD_USER = gql`
  mutation addUser(
    $username: String!
    $email: String!
    $password: String!
    $confirmPassword: String!
  ) {
    registerUser(
      data: {
        username: $username
        email: $email
        password: $password
        confirmPassword: $confirmPassword
      }
    ) {
      id
      username
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation modfiyPassword(
    $oldpwd: String!
    $newpwd: String!
    $confirm: String!
  ) {
    changePassword(
      data: {
        oldPassword: $oldpwd
        newPassword: $newpwd
        confirmPassword: $confirm
      }
    ) {
      username
    }
  }
`;
export default ADD_USER;
export { CHANGE_PASSWORD };
