import React from 'react';
import { Layout, Tabs, Row, Col } from 'antd';
import UserList from '../admin/UserList';
import Theme from '../admin/Theme';
import Card from '../../components/Card/card';

const { TabPane } = Tabs;
const { Content } = Layout;

const SuperAdmin = () => {
  return (
    <Content>
      <Row justify="center" className="mt-36 ">
        <Col className="tabs">
          <Card className="mb-36">
            <Tabs>
              <TabPane tab="User" key="1">
                <UserList />
              </TabPane>
              <TabPane tab="Appearance" key="2">
                <Theme />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </Content>
  );
};
export default SuperAdmin;
