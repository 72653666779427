import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons';
import { Col, Form, Layout, message, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import client from '../../apollo';
import { FORGET_PASSWORD } from './graphql/Query';
import { lynkLogo } from '../../assets/index';
import '../../styles/pages/auth.less';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import Input from '../../components/Input/input';
import { ROUTES } from '../../common/constant';

const { Paragraph } = Typography;
const { Title } = Typography;
const { Content } = Layout;

function ForgetPwd() {
  const history = useHistory();
  const [form] = Form.useForm();
  // eslint-disable-next-line no-undef
  const idToken = localStorage.getItem('jwtToken');
  useEffect(() => {
    if (idToken) {
      history.push(ROUTES.ADMIN);
    } else {
      history.push(ROUTES.RESET);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onFinish = (values) => {
    form.resetFields();
    client
      .query({
        query: FORGET_PASSWORD,
        variables: values
      })
      .then(() => {
        message.success('Reset password link is sent to your register email');
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    message.error(errorInfo);
  };

  return (
    <Content className="login-container d-flex justify-center align-center flex-direction-row mt-20">
      <Row justify="center" gutter={{ xs: 2, sm: 16, md: 24, lg: 32 }}>
        <Title level={2} className="color-00C5E9">
          Forgot Password{' '}
        </Title>
      </Row>
      <Row justify="center" gutter={{ xs: 3, sm: 16, md: 24, lg: 32 }}>
        <Col>
          <Card className="link-card forgot-pwd-card">
            <Row align="middle" justify="center" className="mb-20">
              <Col>
                <img className="logo_img" src={lynkLogo} alt="" logo />
              </Col>
            </Row>
            <Row align="middle" justify="center" className="mb-20">
              <Col>
                <Paragraph className="passwordText">
                  Enter your e-mail to get a password reset link
                </Paragraph>
              </Col>
            </Row>
            <Form
              name="basic"
              initialValues={{
                remember: true
              }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!'
                  }
                ]}
              >
                <Input
                  addonBefore="Email"
                  suffix={<MailOutlined className="color-grey" />}
                  placeholder="E-mail"
                />
              </Form.Item>
              <Form.Item className="mb-0">
                <center>
                  <Button
                    className="btnPrimary"
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    icon={<MailOutlined />}
                    size="small"
                  >
                    Reset
                  </Button>
                </center>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <Row
        justify="center"
        className="mt-20"
        gutter={{ xs: 2, sm: 16, md: 24, lg: 32 }}
      >
        <Link to="/login">
          <ArrowLeftOutlined /> Back To Login Page
        </Link>
      </Row>
    </Content>
  );
}
export default ForgetPwd;
