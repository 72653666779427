import React from 'react';
import Button from './Button/Button';
import { HelpIcon } from '../assets/index';

function HelpButton() {
  return (
    <div>
      <Button
        className="btnSecondary helpButton"
        type="secondary"
        shape="round"
        icon={<img src={HelpIcon} alt="helpIcon" className="helpIcon" />}
      >
        Help
      </Button>
    </div>
  );
}

export default HelpButton;
