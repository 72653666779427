import { Divider, Modal, Typography } from 'antd';
import React, { useContext } from 'react';
import QRcode from 'qrcode.react';
import { Link } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Button from './Button/Button';

const { Paragraph } = Typography;

function ShareLinkModal({ visible, handleOk, handleCancel }) {
  const { state } = useContext(AppContext);
  const currentUser = state.userDetails;

  return (
    <div>
      <Modal
        title="Share Your 1Lynk"
        closable={false}
        visible={visible}
        onOk={() => handleOk()}
        onCancel={() => handleCancel()}
        footer={[
          <Button
            key="submit"
            className="btnSecondary"
            type="secondry"
            size="small"
            shape="round"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        ]}
      >
        <p className="fs-15">QR code :-</p>
        <div className="d-flex justify-center align-center">
          <QRcode
            value={`${process.env.REACT_APP_CLIENT_URL}/${currentUser?.username}`}
          />
        </div>
        <Divider />
        <p className="fs-15">Copy Link:-</p>
        <Paragraph
          copyable={{
            text: `${process.env.REACT_APP_CLIENT_URL}/${currentUser?.username}`
          }}
        >
          <Link to={`/${currentUser?.username}`} target="_blank">
            {currentUser?.link}
          </Link>
        </Paragraph>
      </Modal>
    </div>
  );
}

export default ShareLinkModal;
