import React from 'react';
import { Table, Layout, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Button from '../../components/Button/Button';
import GET_FEATURE from './graphql/Query';

const { Content } = Layout;

function Plans() {
  const history = useHistory();
  const onClick = () => {
    history.push('/register');
  };
  const columns = [
    { title: 'Feature', dataIndex: 'feature', key: 'feature' },
    {
      title: 'Pro Plan',
      dataIndex: 'pro',
      key: 'pro'
    },
    { title: 'Free', dataIndex: 'free', key: 'free' }
  ];

  // eslint-disable-next-line no-unused-vars
  const { loading, error, data: features } = useQuery(GET_FEATURE, {
    fetchPolicy: 'network-only'
  });
  if (loading) return <p>Relax, it's worth the wait...</p>;
  if (error) return <p>Looks like we've got a problem...</p>;

  const data = [
    {
      key: 1,
      feature: 'Unlimited Links',
      description: 'You can add umlimited links on 1lynk'
    },
    {
      key: 2,
      feature: 'QR code',
      description: 'Share your link through Qr code'
    },
    {
      key: 3,
      feature: 'Link Scheduling',
      description: 'Scheduel your link on specific time '
    },
    {
      key: 4,
      feature: 'Priority Link',
      description: 'set a Styling to a link to get attention  '
    },
    {
      key: 5,
      feature: 'Video Links',
      description: 'Embed your youtube link directly to 1lynk'
    },
    {
      key: 6,
      feature: 'Link Thumbnails',
      description: 'create your link more attreactive by adding Thumbnails'
    }
  ];
  return (
    <div>
      <Layout className="layout">
        <Content className="pt-80 pb-80 pb-400 pl-400">
          <div className="d-flex ml-210">
            <Space>
              <p className="ml-50">$4 USD For PRO</p>
              <p className="ml-90">$0 For Free</p>
            </Space>
          </div>
          <div className="d-block ml-200 mb-10">
            <Space>
              <Button shape="round" type="primary" className="ml-50">
                Go With the Pro
              </Button>
              <Button
                shape="round"
                onClick={onClick}
                type="primary"
                className="ml-50"
              >
                Sign up For free
              </Button>
            </Space>
          </div>
          <Table
            size="large"
            columns={columns}
            expandable={{
              expandedRowRender: ({ record }) => (
                <p className="m-0">{record?.description}</p>
              )
            }}
            pagination={false}
            dataSource={data}
          />
        </Content>
      </Layout>
    </div>
  );
}
export default Plans;
