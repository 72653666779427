import { useLazyQuery, useQuery } from '@apollo/client';
import { Affix, Avatar, Spin, Typography, Row, Col } from 'antd';
import { get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { AppContext } from '../AppContext';
import defaultBg from '../assets/images/defaultBg.jpg';
import { GET_CURRENT_USER } from '../modules/auth/graphql/Query';
import GET_LINKS from '../modules/user/graphql/Query';
import '../styles/pages/addLinks.less';
import Button from './Button/Button';
import Card from './Card/card';
import ShareLinkModal from './ShareLinkModal';
import { Logo } from '../assets/index';

const { Paragraph, Text } = Typography;

const Phone = () => {
  const [bgStyle, setBgStyle] = useState({});
  const [btnStyle, setBtnStyle] = useState({});
  const [fontStyles, setFontStyles] = useState({});
  const [user, setUser] = useState({});
  const [firstLetter, setFirstLetter] = useState('');
  const [visible, setVisible] = useState(false);
  const [userLink, setUserLink] = useState(null);

  const userLinkMinChar = 0;
  const userLinkMaxChar = 33;

  const { state } = useContext(AppContext);
  const currentUser = state.userDetails;

  // first fetch the userData and then fetch the userLinks
  const [getUserLinks, { loading: linksLoading, data = {} }] = useLazyQuery(
    GET_LINKS,
    {
      variables: currentUser?.isCustom || false,
      fetchPolicy: 'network-only'
    }
  );

  const { data: getUserData } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (getUserData?.findUser) {
      const { findUser = {} } = getUserData;
      setUser(findUser);
      const { username = '-', isCustom = false, link } = findUser;
      setUserLink(link || '-');
      const {
        backgroundStyle = {},
        buttonStyle = {},
        fontStyle = {}
      } = findUser?.theme;

      if (getUserData?.findUser) {
        getUserLinks({
          variables: {
            custom: findUser?.isCustom || false
          }
        });
      }
      if (username) {
        // eslint-disable-next-line no-shadow
        const firstLetter = username?.toString().charAt(0).toUpperCase() || '';
        setFirstLetter(firstLetter);
      }
      if (!isCustom) {
        setBgStyle({
          ...backgroundStyle,
          textAlign: 'center',
          backgroundPosition: 'center',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat'
        });

        setBtnStyle({
          marginTop: 20,
          width: 200,
          ...buttonStyle,
          borderRadius: 20
        });
        setFontStyles({
          ...fontStyle
        });
      } else {
        const { page = [] } = findUser;
        const {
          background: {
            backgroundImage = '',
            backgroundStyle: backgroundPage = {}
          } = {}
        } = page[0] || {};

        setBgStyle({
          textAlign: 'center',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundImage: `url(${backgroundImage || defaultBg})`,
          ...backgroundPage
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserData]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="device" style={bgStyle}>
        {linksLoading || !user ? (
          <Spin size="small" className="mt-10" />
        ) : (
          <>
            <Affix offsetTop="20">
              <div className="mt-30 scroll-hidden-phone">
                {get(currentUser, ['profileImage']) !== null ? (
                  <Avatar
                    className="avatarBorder"
                    icon={<UserOutlined />}
                    size={57}
                    src={get(currentUser, ['profileImage'])}
                  />
                ) : (
                  <Avatar size={54} className="avatarBorder">
                    {firstLetter}
                  </Avatar>
                )}
                <p className="mb-0 mt-10" style={fontStyles}>
                  {user?.username || '-'}
                </p>
                <div>
                  {data &&
                    data.userLinkList &&
                    data.userLinkList.map((link) => {
                      let customBtn;
                      if (
                        link?.customAppearance &&
                        link?.customAppearance?.button
                      ) {
                        customBtn = {
                          marginTop: 20,
                          ...link?.customAppearance?.button?.buttonStyle,
                          ...link?.customAppearance?.font?.fontStyle
                        };
                      }
                      if (link?.isActive) {
                        return (
                          <div key={link.id}>
                            {get(link, 'type') === 'URL' && (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={link.value || ''}
                              >
                                <Button
                                  key={link.id}
                                  style={user?.isCustom ? customBtn : btnStyle}
                                  className="white-space-pre-wrap"
                                >
                                  {link.title || '-'}
                                </Button>
                              </a>
                            )}
                            {get(link, 'type') === 'EMAIL' && (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${link?.value}`}
                              >
                                <Button
                                  key={link.id}
                                  style={user?.isCustom ? customBtn : btnStyle}
                                  className="white-space-pre-wrap overflow-wrap-anywhere"
                                >
                                  {link.title || '-'}
                                </Button>
                              </a>
                            )}
                            {get(link, 'type') === 'CONTENT' && (
                              <center>
                                <Card
                                  className="quote mt-20 phone-content-card"
                                  size="small"
                                  title={link.title || '-'}
                                >
                                  <Paragraph>{link.value || '-'}</Paragraph>
                                </Card>
                              </center>
                            )}
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>
            </Affix>
            <Row justify="center" align="middle">
              <Col>
                <img className="logo-phone mb-10 mr-5" src={Logo} alt="logo" />
              </Col>
              <Col>
                <p className="color-white mb-10">1Lynk</p>
              </Col>
            </Row>
          </>
        )}
      </div>
      <Card className="sharingLink">
        <Row justify="space-between" align="middle">
          <Col>
            <Paragraph>
              <Text strong>1Lynk: </Text>
              {userLink?.slice(userLinkMinChar, userLinkMaxChar)}
            </Paragraph>
          </Col>
          <Col>
            <Button className="square" block type="primary" onClick={showModal}>
              Share
            </Button>
          </Col>
        </Row>
      </Card>
      <ShareLinkModal
        visible={visible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default Phone;
