import React, { useContext, useEffect, useState } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Card, Col, message, Row, Tag } from 'antd';
import client from '../../../apollo';
import { AppContext } from '../../../AppContext';
import defaultBgForProUser from '../../../assets/images/defaultBgForProUser.jpg';
import Loader from '../../../components/Loader/Loader';
import { GET_CURRENT_USER } from '../../auth/graphql/Query';
import {
  BACKGROUND_CHANGE,
  CHANGE_PAGE_USER,
  SET_CUSTOM_APPERAENCE
} from '../graphql/Mutation';
import GET_LINKS, { GET_BACKGROUND } from '../graphql/Query';
import 'antd/dist/antd.css';

function BackgroundList() {
  const { state, dispatch } = useContext(AppContext);
  const currentUser = state?.userDetails || {};
  const { id: uid, isCustom } = currentUser;
  const [bgIdForSelectedBg, setBgIdForSelectedBg] = useState('');

  const { loading: backgroundLoading, data: background = {} } = useQuery(
    GET_BACKGROUND,
    {
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    if (currentUser?.page)
      setBgIdForSelectedBg(currentUser?.page[0]?.backgroundId);
  }, [currentUser?.page]);

  const onBackgroundClick = (themeId) => {
    setBgIdForSelectedBg(themeId);
    if (!isCustom) {
      client
        .mutate({
          mutation: CHANGE_PAGE_USER,
          variables: {
            uid,
            tid: themeId,
            isCustom: true
          },
          refetchQueries: [{ query: GET_CURRENT_USER }]
        })
        .then((resp) => {
          dispatch({
            type: 'SET_FETCHING_USER_DETAILS',
            data: {
              ...currentUser,
              isCustom: resp?.data?.updateUser?.isCustom
            }
          });
          client
            .mutate({
              mutation: SET_CUSTOM_APPERAENCE,
              variables: {
                pageid: resp?.data?.createPage?.id
              },
              refetchQueries: [
                { query: GET_LINKS, variables: { custom: isCustom } },
                { query: GET_CURRENT_USER }
              ]
            })
            .then(() => {})
            .catch((err) => {
              message.error(err.message);
            });
        })
        .catch((error) => {
          message.error(error.message);
        });
    } else {
      client
        .mutate({
          mutation: BACKGROUND_CHANGE,
          variables: {
            id: themeId
          },
          refetchQueries: [{ query: GET_CURRENT_USER }]
        })
        .then((resp) => {
          const { data = {} } = resp;
          const { createPage = {} } = data;
          client
            .mutate({
              mutation: SET_CUSTOM_APPERAENCE,
              variables: {
                pageid: createPage?.id
              }
            })
            .then(() => {})
            .catch((err) => {
              message.error(err.message);
            });
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };

  if (backgroundLoading) return <Loader />;

  return (
    <>
      <div className="align-center">
        <p className="font-appearance">
          Background{' '}
          <Tag color="#5454ef">
            PRO <LockOutlined />
          </Tag>
        </p>
      </div>
      <Row
        gutter={[48, 16]}
        justify="center"
        className=" container-fluid mt-36"
      >
        {background &&
          background.backgroundList &&
          // eslint-disable-next-line no-shadow
          background.backgroundList.map((background) => {
            const selectedBg =
              bgIdForSelectedBg === background?.id && currentUser?.isCustom;
            return (
              <div
                onClick={() => onBackgroundClick(background?.id)}
                key={background.id}
              >
                <Col>
                  <div className={selectedBg ? 'selected-bg-border' : ''}>
                    <Card
                      hoverable
                      style={{
                        backgroundImage: `url(${
                          background?.backgroundImage || defaultBgForProUser
                        })`,
                        background: background?.backgroundStyle?.background,
                        backgroundSize: '100% 100%',
                        backgroundRepeat: 'no-repeat'
                      }}
                      className="bgList-card"
                    />
                  </div>
                </Col>
              </div>
            );
          })}
      </Row>
    </>
  );
}
export default BackgroundList;
