import jwtDecode from 'jwt-decode';
import React, { createContext, useReducer } from 'react';
import client from './apollo';
import api from './common/api';
import { TOKEN, USER } from './common/constant';
import history from './historyData';

const getLoggedInUser = () => {
  // eslint-disable-next-line no-undef
  let loggedInUser = localStorage.getItem(USER);
  loggedInUser = loggedInUser ? JSON.parse(loggedInUser) : null;

  return loggedInUser;
};

const initialState = {
  currentUser: getLoggedInUser() || {},
  // eslint-disable-next-line no-undef
  authToken: localStorage.getItem(TOKEN)
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_CURRENT_USER':
      return { ...state, currentUser: { ...(action.data || {}) } };
    case 'SET_CURRENT_ROLE':
      return { ...state, currentRole: action.data };
    case 'LOGOUT':
      delete api.defaults.headers.common.Authorization;
      // eslint-disable-next-line no-undef
      localStorage.removeItem(USER);
      // eslint-disable-next-line no-undef
      localStorage.removeItem(TOKEN);
      client.clearStore();
      return {
        ...initialState,
        authenticated: false,
        authToken: null,
        user: {}
      };
    case 'SET_FETCHING_USER_DETAILS':
      return { ...state, userDetails: action.data };

    case 'REMOVE_LINK':
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          userLink: state.userDetails.userLink.filter(
            (link) => link.id !== action.data
          )
        }
      };

    case 'SET_AUTHENTICATED':
      return { ...state, authenticated: action.data };
    case 'SET_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage.setItem(TOKEN, action.data);
      return { ...state, authToken: action.data };
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {}
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const getToken = () => {
    // eslint-disable-next-line no-undef
    return localStorage.getItem(TOKEN) || null;
  };

  const getCurrentUser = () => {
    return state.currentUser || {};
  };

  const getCurrentUserRole = () => {
    const loggedInUser = getLoggedInUser();
    return (loggedInUser && loggedInUser.roles && loggedInUser.roles[0]) || '';
  };

  const isAuthenticated = () => {
    return state.authenticated;
  };

  const initializeAuth = (authToken) => {
    const token = authToken || getToken();
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      const userData = jwtDecode(token);
      if (jwtDecode(token).exp * 1000 < Date.now()) {
        // eslint-disable-next-line no-undef
        dispatch({ type: 'LOGOUT' });
        history.replace('/');
        return;
      }
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_AUTHENTICATED', data: true });
      dispatch({ type: 'SET_CURRENT_USER', data: userData });
      if (userData && userData.roles && userData.roles[0]) {
        dispatch({ type: 'SET_CURRENT_ROLE', data: userData.roles[0] });
      }
    }
  };

  const value = {
    state,
    dispatch,
    isAuthenticated,
    getToken,
    initializeAuth,
    getCurrentUserRole,
    getCurrentUser
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
