import moment from 'moment';

const getTime = (time) => {
  const localDate = moment(time).format('Do MMM YYYY (hh:mm a)');
  return localDate;
};
export default getTime;

export const getColorCode = () => {
  const makeColorCode = '0123456789ABCDEF';
  let code = '#';
  for (let count = 0; count < 6; count += 1) {
    code += makeColorCode[Math.floor(Math.random() * 16)];
  }
  return code;
};
