import { gql } from '@apollo/client';

const CREATE_LINK = gql`
  mutation addLink(
    $title: String!
    $type: String!
    $value: String!
    $from: DateTime
    $to: DateTime
  ) {
    createUserLink(
      data: { title: $title, type: $type, value: $value, from: $from, to: $to }
    ) {
      id
      title
      value
      type
      position
      from
      to
      isActive
    }
  }
`;

const MODIFY_LINK = gql`
  mutation updateLink(
    $id: ID!
    $title: String
    $value: String
    $isActive: Boolean
    $from: DateTime
    $to: DateTime
  ) {
    updateUserLink(
      id: $id
      data: {
        title: $title
        value: $value
        isActive: $isActive
        from: $from
        to: $to
      }
    ) {
      id
      title
      value
      type
      isActive
    }
  }
`;

const DELETE_USERLINK = gql`
  mutation deleteLink($id: ID!) {
    removeUserLink(data: { id: $id }) {
      value
      title
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUserDetails(
    $id: ID!
    $username: String
    $email: String
    $isCustom: Boolean
    $profileImage: String
    $link: String
  ) {
    updateUser(
      id: $id
      data: {
        username: $username
        email: $email
        isCustom: $isCustom
        profileImage: $profileImage
        link: $link
      }
    ) {
      username
      email
      isCustom
      profileImage
      link
    }
  }
`;

const CHANGE_PAGE_USER = gql`
  mutation customeUser($uid: ID!, $tid: ID!, $isCustom: Boolean) {
    updateUser(id: $uid, data: { isCustom: $isCustom }) {
      id
      username
      link
      isCustom
    }
    createPage(data: { backgroundId: $tid }) {
      id
      isActive
    }
  }
`;

const UPDATE_USERTHEME = gql`
  mutation changeTheme($id: ID!) {
    updateUserTheme(data: { themeId: $id }) {
      id
      userId
      themeId
      theme {
        id
        title
        backgroundStyle
        buttonStyle
        fontStyle
        image
        planId
        isActive
      }
    }
  }
`;

const BACKGROUND_CHANGE = gql`
  mutation backgroundChange($id: ID!) {
    createPage(data: { backgroundId: $id }) {
      id
      isActive
    }
  }
`;
const SET_CUSTOM_APPERAENCE = gql`
  mutation addCustomApperence(
    $userlinkid: ID
    $btnid: ID
    $fontid: ID
    $pageid: ID
  ) {
    createCustomAppearance(
      data: {
        userlinkId: $userlinkid
        buttonId: $btnid
        fontId: $fontid
        pageId: $pageid
      }
    ) {
      page {
        id
      }
    }
  }
`;

const LINK_DRAGDROP = gql`
  mutation linkOrder($old: Int!, $new: Int!) {
    dragDrop(data: { oldIndex: $old, newIndex: $new }) {
      id
    }
  }
`;
export default MODIFY_LINK;
export {
  DELETE_USERLINK,
  CREATE_LINK,
  UPDATE_USER,
  UPDATE_USERTHEME,
  LINK_DRAGDROP,
  BACKGROUND_CHANGE,
  CHANGE_PAGE_USER,
  SET_CUSTOM_APPERAENCE
};
