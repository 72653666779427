import React from 'react';
import moment from 'moment';
import { useLazyQuery } from '@apollo/client';
import { Form, Modal, DatePicker, message } from 'antd';
import { GET_LINK } from '../graphql/Query';
import MODIFY_LINK from '../graphql/Mutation';
import client from '../../../apollo';
import Button from '../../../components/Button/Button';
import { Calender } from '../../../assets/index';

const { RangePicker } = DatePicker;

const TimeScheduleModal = ({
  linkId,
  isTimeModalVisible,
  showTimeModalHandler,
  fromVal,
  toVal
}) => {
  const [form] = Form.useForm();
  // set default value of form input
  form.setFieldsValue({
    Picker: [moment(fromVal), moment(toVal)]
  });

  // query
  const [getScheduleDataLinkById] = useLazyQuery(GET_LINK, {
    onCompleted(data = {}) {
      const { from, to } = data?.findUserLinkList;
      // format date
      const formateFrom = moment(from).format('YYYY-MM-DD HH:mm:ss').toString();
      const formateTo = moment(to).format('YYYY-MM-DD HH:mm:ss').toString();
      form.setFieldsValue({
        Picker: [moment(formateFrom), moment(formateTo)]
      });
    }
  });

  // schedule change handler
  const onScheduleChange = (_, dateString) => {
    getScheduleDataLinkById({
      variables: {
        id: linkId
      }
    });

    client
      .mutate({
        mutation: MODIFY_LINK,
        variables: {
          id: linkId,
          from: dateString[0] || null,
          to: dateString[1]
        },
        refetchQueries: [{ query: GET_LINK, variables: { id: linkId } }]
      })
      .then(() => {
        message.success('Link Rescheduled');
      })
      .catch((err) => {
        message.error(err.message);
      });
    showTimeModalHandler(false);
  };

  return (
    <div>
      <Modal
        closable={false}
        key={linkId}
        title="Update Schedule"
        visible={isTimeModalVisible}
        onCancel={() => showTimeModalHandler(false)}
        footer={[
          <Button
            className="btnSecondary"
            size="small"
            key="submit"
            shape="round"
            onClick={() => showTimeModalHandler(false)}
          >
            Cancel
          </Button>
        ]}
      >
        <Form form={form} onFinish={() => showTimeModalHandler(false)}>
          <Form.Item className="mb-0" name="Picker">
            <RangePicker
              use12Hours
              suffixIcon={<img src={Calender} alt="arrow" />}
              disabledDate={(current) => {
                return moment().add(-1, 'days') >= current;
              }}
              className="rangePicker"
              showTime
              onChange={onScheduleChange}
              format="YYYY-MM-DD HH:mm:ss"
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TimeScheduleModal;
