import { CheckOutlined, CloseOutlined, LockOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
  Col,
  Empty,
  Form,
  message,
  Row,
  Space,
  Spin,
  Switch,
  Tag,
  Typography
} from 'antd';
import 'antd/dist/antd.css';
import React, { useEffect, useRef, useState } from 'react';
import client from '../../../apollo';
import { GET_CURRENT_USER } from '../../auth/graphql/Query';
import { SET_CUSTOM_APPERAENCE } from '../graphql/Mutation';
import GET_LINKS, { GET_FONTS } from '../graphql/Query';
import Button from '../../../components/Button/Button';
import Card from '../../../components/Card/card';
import Modal from '../../../components/Modal/Modal';

function FontList() {
  const fontRef = useRef();
  const [fontid, setFontId] = useState(1);
  const [checkcustom, setCheckcustom] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { Text } = Typography;
  const { loading, data = {} } = useQuery(GET_FONTS, {
    fetchPolicy: 'network-only'
  });
  const { data: userData = {} } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (userData && userData.findUser) {
      const { findUser: { isCustom: check } = {} } = userData;
      setCheckcustom(check);
    }
  }, [userData]);
  const { data: linkData } = useQuery(GET_LINKS, {
    variables: {
      custom: checkcustom
    }
  });
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    fontRef.current.resetFields();
  };

  const onFontChange = (switchOn, linkId) => {
    client
      .mutate({
        mutation: SET_CUSTOM_APPERAENCE,
        variables: {
          userlinkid: linkId,
          fontid: switchOn ? fontid : 1
        },
        refetchQueries: [
          { query: GET_CURRENT_USER },
          { query: GET_LINKS, variables: { custom: checkcustom } }
        ]
      })
      .then(() => {})
      .catch((error) => {
        message.error(error.message);
      });
  };

  return (
    <>
      <div className="align-center">
        <p className="font-appearance">
          Fonts{' '}
          <Tag color="#5454ef">
            PRO <LockOutlined />
          </Tag>
        </p>
      </div>
      <Row
        gutter={[16, 28]}
        justify="center"
        className=" container-fluid mt-36 mb-36"
      >
        {data &&
          data.fontList &&
          data.fontList.map((font) => {
            return (
              <Spin key={font.id} spinning={loading}>
                <div onClick={() => setFontId(font.id)}>
                  <Col>
                    <Card
                      grid={{
                        gutter: 16,
                        xs: 2,
                        sm: 2,
                        md: 3,
                        lg: 3,
                        xl: 3,
                        xxl: 3
                      }}
                      onClick={showModal}
                      className="fontList-card"
                    >
                      {data?.fontList && (
                        <span
                          style={{
                            fontSize: 25,
                            ...(font.fontStyle || {
                              fontFamily: 'monospace'
                            })
                          }}
                        >
                          1Lynk
                        </span>
                      )}
                    </Card>
                  </Col>
                </div>
              </Spin>
            );
          })}
      </Row>
      <Modal
        title="Select Fonts"
        key={fontid}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button
            className="btnSecondary"
            type="secondry"
            size="small"
            shape="round"
            key="submit"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        ]}
        content={
          <Form
            ref={fontRef}
            name="buttonModal"
            initialValues={{
              remember: true
            }}
            autoComplete="false"
          >
            {linkData &&
            linkData.userLinkList &&
            linkData.userLinkList.length > 0 ? (
              linkData.userLinkList.map((link) => {
                if (link.isActive && link.type !== 'CONTENT') {
                  return (
                    <Form.Item className="btn-switch" name="link" key={link.id}>
                      <Space>
                        <Row>
                          <Col className="mr-10">
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              onChange={(e) => onFontChange(e, link.id)}
                              defaultChecked={
                                link?.customAppearance?.font?.id === fontid
                              }
                            />
                          </Col>
                          <Col>
                            <Text>{link.title}</Text>
                          </Col>
                        </Row>
                      </Space>
                    </Form.Item>
                  );
                }
                return null;
              })
            ) : (
              <Empty />
            )}
          </Form>
        }
      />
    </>
  );
}
export default FontList;
