/* eslint-disable no-nested-ternary */
import { Input as AntInput } from 'antd';
import { React, useState } from 'react';
import './styles.less';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

const Input = ({
  className,
  children,
  addonBefore,
  allowClear,
  defaultValue,
  prefix,
  suffix,
  type,
  value,
  onChange,
  placeholder,
  size
}) => {
  const [currentType, setCurrentType] = useState(false);
  return (
    <AntInput
      placeholder={placeholder}
      className={className}
      addonBefore={addonBefore}
      allowClear={allowClear}
      defaultValue={defaultValue}
      prefix={prefix}
      suffix={
        type === 'password' ? (
          currentType ? (
            <EyeOutlined onClick={() => setCurrentType(false)} />
          ) : (
            <EyeInvisibleOutlined onClick={() => setCurrentType(true)} />
          )
        ) : (
          suffix
        )
      }
      type={type === 'password' ? (currentType ? 'text' : 'password') : type}
      size={size}
      value={value}
      onChange={onChange}
    >
      {children}
    </AntInput>
  );
};
export default Input;
