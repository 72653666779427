import { gql } from '@apollo/client';

export const GENERATE_S3_SIGNED_URL = gql`
  mutation s3sign($fileName: String!, $fileType: String!) {
    signS3(data: { fileName: $fileName, fileType: $fileType }) {
      url
      signedRequest
    }
  }
`;

export const ADD_THEME = gql`
  mutation addTheme(
    $title: String!
    $backgroundStyle: JSON
    $buttonStyle: JSON!
    $fontStyle: JSON!
    $image: String!
    $planId: ID!
  ) {
    createTheme(
      data: {
        title: $title
        backgroundStyle: $backgroundStyle
        buttonStyle: $buttonStyle
        fontStyle: $fontStyle
        image: $image
        planId: $planId
      }
    ) {
      id
    }
  }
`;

export const UPDATE_THEME = gql`
  mutation updateTheme(
    $id: ID!
    $title: String!
    $backgroundStyle: JSON
    $buttonStyle: JSON!
    $fontStyle: JSON!
    $image: String!
    $planId: ID!
  ) {
    updateTheme(
      id: $id
      data: {
        title: $title
        backgroundStyle: $backgroundStyle
        buttonStyle: $buttonStyle
        fontStyle: $fontStyle
        image: $image
        planId: $planId
      }
    ) {
      id
    }
  }
`;

export const DELETE_THEME = gql`
  mutation deleteTheme($id: ID!) {
    removeTheme(data: { id: $id }) {
      id
    }
  }
`;
