import { Menu, Affix, Drawer, Avatar } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import { AppContext } from '../../AppContext';
import '../../styles/pages/userNavbar.less';
import { ROUTES } from '../../common/constant';
import { UserIcon, Logo } from '../../assets/index';
import ShareLinkModal from '../../components/ShareLinkModal';
import Button from '../../components/Button/Button';

const { SubMenu } = Menu;

function UserNavbar() {
  const [current, setCurrent] = useState('Links');
  const [visible, setVisible] = useState(false);
  const [drawervisible, setDrawerVisible] = useState(false);
  const { dispatch, state, getToken } = useContext(AppContext);
  const currentUser = state?.userDetails;
  const idToken = getToken();
  const history = useHistory();

  const showDrawer = () => {
    setDrawerVisible(true);
  };
  const onClose = () => {
    setDrawerVisible(false);
  };
  useEffect(() => {
    const pathName = history.location.pathname;
    if (pathName !== '/login') {
      const key = pathName.substring(7);
      setCurrent(key.charAt(0).toUpperCase() + key.slice(1));
    } else if (pathName === '/profile') {
      setCurrent('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, history]);

  const signout = () => {
    dispatch({ type: 'LOGOUT' });
    history.push('/');
  };

  const handleClick = (e) => {
    setCurrent(e.key);
    setDrawerVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      {idToken ? (
        <Affix offsetTop={0}>
          <div>
            <div className="user-navbar-container">
              <Link to={ROUTES.LINKS} onClick={handleClick}>
                <img src={Logo} alt="logo" className="logo" />
              </Link>
              <div className="center menu">
                <Menu
                  onClick={handleClick}
                  selectedKeys={[current]}
                  mode="horizontal"
                >
                  <Menu.Item key="Links">
                    <Link to={ROUTES.LINKS}>Links</Link>
                  </Menu.Item>
                  <Menu.Item key="Appearance">
                    <Link to={ROUTES.APPEARANCE}>Appearance</Link>
                  </Menu.Item>
                  <Menu.Item key="Analysis">
                    <Link to={ROUTES.ANALYSIS}>Analysis</Link>
                  </Menu.Item>
                  <Menu.Item key="Settings">
                    <Link to={ROUTES.SETTINGS}>Settings</Link>
                  </Menu.Item>
                </Menu>
              </div>
              <div className="right mobile-nav">
                <Menu mode="horizontal" onClick={handleClick}>
                  <SubMenu
                    key="sub1"
                    icon={
                      get(currentUser, ['profileImage']) !== null ? (
                        <Avatar
                          size={30}
                          src={get(currentUser, ['profileImage'])}
                          icon={<UserOutlined />}
                        />
                      ) : (
                        <Avatar className="defaultLogo" src={UserIcon} />
                        // this is needed for future //
                        // <Avatar>
                        //   {currentUser.username
                        //     ?.toString()
                        //     ?.charAt(0)
                        //     ?.toUpperCase() || '-'}
                        // </Avatar>
                      )
                    }
                  >
                    <Menu.Item key="1">
                      <Link to="/admin/profile">My Profile</Link>
                    </Menu.Item>
                    <Menu.Item key="2" onClick={showModal}>
                      Share Link
                    </Menu.Item>
                    <Menu.Item key="3" onClick={signout}>
                      Logout
                    </Menu.Item>
                  </SubMenu>
                </Menu>
                <div className="responsive-menu">
                  <Button
                    className="iconBtn"
                    icon={<MenuOutlined />}
                    onClick={showDrawer}
                  />
                  <Drawer
                    title="1Lynk"
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    visible={drawervisible}
                  >
                    <Menu
                      className="vertical-menu"
                      onClick={handleClick}
                      selectedKeys={[current]}
                      mode="vertical"
                    >
                      <Menu.Item key="Links">
                        <Link to={ROUTES.LINKS}>Links</Link>
                      </Menu.Item>
                      <Menu.Item key="Appearance">
                        <Link to={ROUTES.APPEARANCE}>Appearance</Link>
                      </Menu.Item>
                      <Menu.Item key="Analysis">
                        <Link to={ROUTES.ANALYSIS}>Analysis</Link>
                      </Menu.Item>
                      <Menu.Item key="Settings">
                        <Link to={ROUTES.SETTINGS}>Settings</Link>
                      </Menu.Item>
                    </Menu>
                  </Drawer>
                </div>
              </div>
            </div>
            <ShareLinkModal
              visible={visible}
              handleOk={handleOk}
              handleCancel={handleCancel}
            />
          </div>
        </Affix>
      ) : null}
    </>
  );
}
export default UserNavbar;
