import React from 'react';
import { Modal as AntModal } from 'antd';
import './Styles.less';

export default function (props) {
  const {
    content,
    footer,
    height,
    maxHeight,
    title,
    width,
    onOk,
    visible,
    handleCancel,
    className,
    closable
  } = props;

  return (
    <AntModal
      closable={closable}
      className={className}
      title={title}
      visible={visible}
      onCancel={handleCancel}
      onOk={onOk}
      width={width}
      height={height}
      maxHeight={maxHeight}
      footerPadding={!!footer}
      footer={footer === 'default' ? undefined : [footer]}
    >
      {content}
    </AntModal>
  );
}
