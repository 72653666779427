/* eslint-disable camelcase */
import { LinkOutlined, LoadingOutlined, MailOutlined } from '@ant-design/icons';
import { Avatar, Col, message, Row, Spin, Typography, Layout } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ImQuotesLeft, ImQuotesRight } from 'react-icons/im';
import client from '../../apollo';
import defaultBg from '../../assets/images/defaultBg.jpg';
import { GET_SHARABLE_LINK } from './graphql/Query';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import { Logo } from '../../assets/index';

const { Paragraph, Title } = Typography;
const { Content } = Layout;

const SharableLink = (props) => {
  const [bgStyle, setBgStyle] = useState({});
  const [btnStyle, setBtnStyle] = useState({});
  const [fontStyles, setFontStyles] = useState({});
  const [firstLetter, setFirstLetter] = useState('');
  const [userData, setUserData] = useState(null);
  const [userLinks, setUserLinks] = useState([]);

  const antIcon = <LoadingOutlined className="fs-50" spin />;
  const { match } = props;
  const { params } = match;

  const name = params?.username;

  useEffect(() => {
    client
      .query({
        query: GET_SHARABLE_LINK,
        variables: {
          username: name
        },
        fetchPolicy: 'network-only'
      })
      .then((resp) => {
        const { findShareableLink = {} } = resp?.data;
        setUserData(findShareableLink);
        if (findShareableLink?.userLink) {
          setUserLinks(findShareableLink.userLink);
        }

        const { username = '-', isCustom = false } = findShareableLink;
        const {
          backgroundStyle = {},
          buttonStyle = {},
          fontStyle = {}
        } = findShareableLink?.theme;

        if (username) {
          // eslint-disable-next-line no-shadow
          const firstLetter =
            username?.toString()?.charAt(0)?.toUpperCase() || '';
          setFirstLetter(firstLetter);
        }
        if (!isCustom) {
          setBgStyle({
            textAlign: 'center',
            backgroundPosition: 'center center',
            backgroundAttachment: 'fixed',
            minHeight: '100vh',
            overFlow: 'scroll',
            ...backgroundStyle
          });
          setBtnStyle({
            marginTop: 20,
            height: 55,
            borderRadius: 50,
            fontSize: 'larger',
            ...buttonStyle
          });
          setFontStyles({
            ...fontStyle
          });
        } else {
          const { page = [] } = findShareableLink;
          const {
            background: {
              backgroundImage = '',
              backgroundStyle: backgroundPage = {}
            } = {}
          } = page[0] || {};

          setBgStyle({
            textAlign: 'center',
            backgroundImage: `url(${backgroundImage || defaultBg})`,
            backgroundPosition: 'center center',
            backgroundAttachment: 'fixed',
            backgroundSize: 'cover',
            minHeight: '100vh',
            overFlow: 'scroll',
            ...backgroundPage
          });
        }
      })
      .catch((err) => {
        message.error(err.message);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Content>
        {!userData ? (
          <Row justify="center">
            <Col>
              <Spin indicator={antIcon} className="mt-150" size="large" />
            </Col>
          </Row>
        ) : (
          <div className="shareLayout">
            <div style={bgStyle}>
              {get(userData, ['profileImage']) !== null ? (
                <Avatar
                  size={100}
                  src={get(userData, ['profileImage'])}
                  className="avatarBorder mt-50"
                />
              ) : (
                <Avatar size={100} className="avatarBorder mt-50">
                  {firstLetter || '-'}
                </Avatar>
              )}
              <p className="mb-0 mt-10" style={fontStyles}>
                {userData?.username}
              </p>
              <div>
                {userData &&
                  userLinks?.map((link) => {
                    let customBtn;
                    if (
                      link?.customAppearance &&
                      link?.customAppearance?.button
                    ) {
                      customBtn = {
                        marginTop: 20,
                        height: 55,
                        ...link?.customAppearance?.button?.buttonStyle,
                        ...link?.customAppearance?.font?.fontStyle,
                        fontSize: 'larger'
                      };
                    }
                    if (link?.isActive) {
                      return (
                        <Row key={link.id} justify="center">
                          <Col key={link.id}>
                            {get(link, 'type') === 'URL' && (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={link.value || ''}
                              >
                                <Button
                                  key={link.id}
                                  icon={<LinkOutlined />}
                                  style={
                                    userData?.isCustom ? customBtn : btnStyle
                                  }
                                  className="white-space-pre-wrap h-auto"
                                >
                                  {link.title || ''}
                                </Button>
                              </a>
                            )}
                            {get(link, 'type') === 'EMAIL' && (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={
                                  isMobile
                                    ? `mailto:${link.value}?`
                                    : `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${link.value}`
                                }
                              >
                                <Button
                                  key={link.id}
                                  icon={<MailOutlined />}
                                  style={
                                    userData?.isCustom ? customBtn : btnStyle
                                  }
                                  className="white-space-pre-wrap h-auto"
                                >
                                  {link.title || ''}
                                </Button>
                              </a>
                            )}
                            {get(link, 'type') === 'CONTENT' && (
                              <center>
                                <Card
                                  className="quote mt-20 phone-content-card"
                                  size="default"
                                  title={link.title || ''}
                                >
                                  <Paragraph>
                                    <ImQuotesLeft />
                                    &nbsp;&nbsp; {link.value || '-'}{' '}
                                    &nbsp;&nbsp;
                                    <ImQuotesRight />
                                  </Paragraph>
                                </Card>
                              </center>
                            )}
                          </Col>
                        </Row>
                      );
                    }
                    return null;
                  })}
              </div>
              <Row justify="center" align="middle">
                <Col>
                  <img
                    className="logo-phone mt-10 mb-20 mr-5"
                    src={Logo}
                    alt="logo"
                  />
                </Col>
                <Col>
                  <Title level={5} className="color-white mt-10 mb-20">
                    1Lynk
                  </Title>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Content>
    </>
  );
};
export default SharableLink;
