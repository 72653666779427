import { Col, message, Row, Layout } from 'antd';
import { useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import client from '../../../apollo';
import { AppContext } from '../../../AppContext';
import { LINK_DRAGDROP } from '../graphql/Mutation';
import GET_LINKS, { GET_SCHEDULE_LINK } from '../graphql/Query';
import AddLinkModal from './AddLinkModal';
import { SortableList } from './SortableList';
import Phone from '../../../components/Phone';
import HelpButton from '../../../components/HelpButton';

const { Content } = Layout;
const AddLink = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  // fetch current user from context
  const { state } = useContext(AppContext);
  const currentUser = state.userDetails;
  // add link modal handler
  const addLinkModalHandler = (isShow) => {
    setIsModalVisible(isShow);
  };

  const { data: items = {} } = useQuery(GET_SCHEDULE_LINK, {
    variables: {
      custom: currentUser?.isCustom || false
    },
    fetchPolicy: 'network-only'
  });

  const onSortEnd = (data) => {
    client
      .mutate({
        mutation: LINK_DRAGDROP,
        variables: {
          old: data?.oldIndex,
          new: data?.newIndex
        },
        context: data,
        refetchQueries: [
          {
            query: GET_LINKS,
            variables: { custom: currentUser?.isCustom || false }
          }
        ]
      })
      .then(() => {})
      .catch((err) => {
        message.error(err.message);
      });
    // eslint-disable-next-line no-undef
    document.body.style.cursor = 'default';
  };

  return (
    <Content className="content">
      <Row justify="space-between" className="links-Wrapper">
        <Col order={1} flex="auto">
          {items?.userLinkListDisplaysScheduling?.length > 0 && (
            <Row justify="center" className="mt-36">
              <Col className="add-links" span={11}>
                <AddLinkModal
                  addLinkModalHandler={addLinkModalHandler}
                  isModalVisible={isModalVisible}
                />
              </Col>
            </Row>
          )}
          <Row justify="center" className="sortableList">
            <Col>
              <SortableList
                axis="y"
                useDragHandle
                onSortEnd={onSortEnd}
                // eslint-disable-next-line no-return-assign
                onSortStart={() =>
                  // eslint-disable-next-line no-undef
                  (document.body.style.cursor = 'grabbing')
                }
              />
            </Col>
          </Row>
        </Col>
        <Col className="phoneContainer" order={2}>
          <Row justify="center" align="middle">
            <Col className="device-wrapper">
              <Phone />
            </Col>
          </Row>
        </Col>
      </Row>
      <HelpButton />
    </Content>
  );
};

export default AddLink;
