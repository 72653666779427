import React, { useEffect, useState } from 'react';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Typography,
  Row,
  Tabs,
  Upload,
  Spin
} from 'antd';
import axios from 'axios';
import { isEmpty } from 'lodash';
import {
  ADD_THEME,
  GENERATE_S3_SIGNED_URL,
  UPDATE_THEME
} from '../../modules/admin/graphql/mutation';
import { GET_THEMES } from '../../modules/user/graphql/Query';
import Modal from '../Modal/Modal';
import { getColorCode } from '../../common/utils';
import FontList from '../../modules/admin/FontList';

const { TabPane } = Tabs;
const { Title } = Typography;

const ThemeModal = ({
  isModalVisible,
  setIsModalVisible,
  editThemeData,
  editThemeDataHandler
}) => {
  const [form] = Form.useForm();

  const [currentFile, setCurrentFile] = useState({});
  const [themeImgURL, setThemeImgURL] = useState('');
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const fileSelectedOrNot = Object.keys(currentFile).length;
  const [themeName, setThemeName] = useState(editThemeData?.title);

  // color picker states
  // for button
  const [btnBgColor, setBtnBgColor] = useState(getColorCode() || '');
  const [btnBorderColor, setBtnBorderColor] = useState('none');
  const [btnTextColor, setBtnTextColor] = useState(getColorCode() || '');

  // for background
  const [firstGradientColor, setFirstGradientColor] = useState(
    getColorCode() || ''
  );
  const [secondGradientColor, setSecondGradientColor] = useState(
    getColorCode() || ''
  );

  // for font
  const [textStyle, setTextStyle] = useState({});

  // theme data for edit the theme
  useEffect(() => {
    const {
      backgroundStyle = {},
      buttonStyle = {},
      fontStyle = {},
      image = '',
      title
    } = editThemeData;

    if (image) setThemeImgURL(image);
    if (buttonStyle) {
      setBtnBgColor(buttonStyle?.backgroundColor);
      setBtnBorderColor(buttonStyle?.borderColor);
      setBtnTextColor(buttonStyle?.color);
    }

    if (backgroundStyle) {
      const { background } = backgroundStyle;
      if (background) {
        const firstColor = background.substr(24, 7);
        const secondColor = background.substr(36, 7);
        setFirstGradientColor(firstColor);
        setSecondGradientColor(secondColor);
      }
    }

    if (fontStyle) setTextStyle(fontStyle);
    if (title) setThemeName(title);
  }, [editThemeData]);

  // generate signed url
  const [generateS3SignedLink] = useMutation(GENERATE_S3_SIGNED_URL, {
    async onCompleted(data) {
      const options = {
        headers: {
          'Content-Type': currentFile.type
        }
      };
      // upload file on s3 using signed url
      const uploadFileOnS3 = await axios.put(
        data.signS3.signedRequest,
        currentFile,
        options
      );
      setFileUploadLoader(false);
      // check file uploaded successfully or not
      if (uploadFileOnS3.status === 200) {
        setThemeImgURL(data.signS3.url);
        message.success('file uploaded successfully');
      }
    },
    onError(err) {
      message.error(err.message);
    }
  });

  // add theme
  const [addNewTheme] = useMutation(ADD_THEME, {
    onCompleted() {
      message.success('New theme added successfully');
    }
  });

  // update theme
  const [updateTheme] = useMutation(UPDATE_THEME, {
    onCompleted() {
      message.success('Theme updated successfully');
    }
  });

  const handleOk = async () => {
    const planId = 1;

    const backgroundObj = {
      background: themeImgURL
        ? `url(${themeImgURL})`
        : `linear-gradient(180deg, ${firstGradientColor} 0%, ${secondGradientColor} 100%)`
    };

    const buttonObj = {
      width: '800px',
      color: btnTextColor,
      maxWidth: '80%',
      backgroundColor: btnBgColor,
      borderColor: btnBorderColor
    };

    const fontObj = textStyle;

    // theme validation
    if (!themeName) return message.error('Theme title is required');
    if (isEmpty(fontObj)) return message.error('Font style is required');
    if (isEmpty(buttonObj)) return message.error('Button style is required');
    if (!btnBgColor)
      return message.error('Button background style is required');
    if (!btnTextColor)
      return message.error('Button text color style is required');
    if (isEmpty(backgroundObj))
      return message.error('Background style is required');

    if (editThemeData) {
      updateTheme({
        variables: {
          // eslint-disable-next-line radix
          id: parseInt(editThemeData?.id),
          title: themeName,
          backgroundStyle: backgroundObj,
          buttonStyle: buttonObj,
          fontStyle: fontObj,
          image: themeImgURL,
          planId: planId // provide a options to admin for select a plan
        },
        refetchQueries: [{ query: GET_THEMES }]
      });
      // clear editThemeData state
      editThemeDataHandler('');
    } else {
      addNewTheme({
        variables: {
          title: themeName,
          backgroundStyle: backgroundObj,
          buttonStyle: buttonObj,
          fontStyle: fontObj,
          image: themeImgURL,
          planId: planId // provide a options to admin for select a plan
        },
        refetchQueries: [{ query: GET_THEMES }]
      });
    }

    setThemeName('');
    // set random color
    setBtnBgColor(getColorCode() || '');
    setBtnTextColor(getColorCode() || '');
    setBtnBorderColor('none');
    setFirstGradientColor(getColorCode() || '');
    setSecondGradientColor(getColorCode() || '');
    setIsModalVisible(false);
    setCurrentFile({});
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    editThemeDataHandler({});
    setThemeName('');
    setThemeImgURL('');
    form.resetFields();
  };

  const uploadFileOnS3 = (fileName, fileType) => {
    setFileUploadLoader(true);
    generateS3SignedLink({
      variables: {
        fileName,
        fileType
      }
    });
  };

  // upload file props
  const props = {
    accept: '.jpg,.png,.svg',
    multiple: false,
    beforeUpload: (file) => {
      setCurrentFile(file);
      uploadFileOnS3(file.name, file.type);
      return false;
    },
    showUploadList: false,
    fileList: fileSelectedOrNot ? [currentFile] : []
  };

  // color picker handlers
  // for button
  const btnBgColorHandler = (e) => {
    setBtnBgColor(e.target.value);
  };

  const btnBorderColorHandler = (e) => {
    setBtnBorderColor(e.target.value);
  };

  const btnTextColorHandler = (e) => {
    setBtnTextColor(e.target.value);
  };

  // for background
  const firstGradientColorHandler = (e) => {
    setFirstGradientColor(e.target.value);
  };

  const secondGradientColorHandler = (e) => {
    setSecondGradientColor(e.target.value);
  };

  // font style handler
  const fontStyleHandler = (txtStyle) => {
    setTextStyle(txtStyle);
  };

  const themeTitleHandler = (e) => {
    e.preventDefault();
    setThemeName(e.target.value);
  };

  return (
    <Modal
      width={800}
      closable={false}
      title="Themes"
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <>
          <Row justify="end" align="middle">
            <Col>
              {' '}
              <Button
                key="submit"
                className="btnLink"
                type="link"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Col>
            <Form
              form={form}
              name="basic"
              autoComplete="false"
              onFinish={handleOk}
            >
              <Form.Item className="mb-0">
                <Button
                  type="secondary"
                  size="small"
                  shape="round"
                  className="btnSecondary"
                  htmlType="submit"
                  disabled={fileUploadLoader}
                >
                  {editThemeData.title ? 'Update Theme' : 'Add Theme'}
                </Button>
              </Form.Item>
            </Form>
            <Col />
          </Row>
        </>
      ]}
      content={
        <>
          <Input
            addonBefore="Title"
            value={themeName}
            onChange={themeTitleHandler}
            className="mb-10"
          />
          <Card title="Font Styles" className="mb-20">
            <FontList
              fontStyleHandler={fontStyleHandler}
              txtStyle={textStyle}
            />
          </Card>
          <Card title="Button Styles" className="mb-20">
            <Row justify="space-around" align="middle">
              <Col span={10}>
                <Row align="middle" className="mb-20">
                  <Col className="mr-10">
                    <Title className="modal-title">Background :</Title>
                  </Col>
                  <Col className="mr-10">
                    <Input
                      type="color"
                      name="btnBgColor"
                      value={btnBgColor}
                      onChange={btnBgColorHandler}
                    />
                  </Col>
                  <Col>{btnBgColor}</Col>
                </Row>
                <Row align="middle" className="mb-20">
                  <Col className="mr-10">
                    <Title className="modal-title">Text Color :</Title>
                  </Col>
                  <Col className="mr-10">
                    <Input
                      type="color"
                      name="btnBgColor"
                      value={btnTextColor}
                      onChange={btnTextColorHandler}
                    />
                  </Col>
                  <Col>{btnTextColor}</Col>
                </Row>
                <Row align="middle">
                  <Col className="mr-10">
                    <Title className="modal-title">Border Color :</Title>
                  </Col>
                  <Col className="mr-10">
                    <Input
                      type="color"
                      name="btnBgColor"
                      value={btnBorderColor}
                      onChange={btnBorderColorHandler}
                    />
                  </Col>
                  <Col>{btnBorderColor}</Col>
                </Row>
              </Col>
              <Col span={8}>
                <Button
                  style={{
                    backgroundColor: btnBgColor,
                    color: btnTextColor,
                    borderRadius: '8px',
                    border: `1px solid ${btnBorderColor}`,
                    fontWeight: '500',
                    ...textStyle
                  }}
                >
                  Button preview
                </Button>
              </Col>
            </Row>
          </Card>
          <Card>
            <Tabs>
              <TabPane tab="Background Gradient" key="1">
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Col className="mr-10 mb-10">
                      <Title className="modal-title">Select Gradient</Title>
                    </Col>
                    <Row gutter={[16, 16]} justify="center" align="middle">
                      <Col className="gradient">
                        <Input
                          className="mb-10"
                          type="color"
                          name="firstGradientColor"
                          value={firstGradientColor}
                          onChange={firstGradientColorHandler}
                        />
                        {firstGradientColor}
                      </Col>
                      <Col>
                        <Input
                          className="mb-10 gradient"
                          type="color"
                          name="secondGradientColor"
                          value={secondGradientColor}
                          onChange={secondGradientColorHandler}
                        />
                        {secondGradientColor}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="background-title" span={11}>
                    <Title className="modal-title">Background preview</Title>
                    <div
                      className="background-preview"
                      style={{
                        background: `linear-gradient(180deg,${firstGradientColor} 0%, ${secondGradientColor} 100%)`
                      }}
                    />
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Background Image" key="2">
                <Row justify="space-between" align="middle">
                  <Col span={10}>
                    <Col className="mb-10">
                      <Title className="modal-title">
                        Upload Background Image
                      </Title>
                    </Col>
                    <Row justify="center" align="middle">
                      <Col>
                        {fileUploadLoader ? (
                          <Spin />
                        ) : (
                          <Form.Item name="upload">
                            <Upload {...props} disabled={themeImgURL}>
                              <Button icon={<UploadOutlined />}>
                                Click to upload
                              </Button>
                            </Upload>
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="background-title" span={11}>
                    <Title className="modal-title">Image preview</Title>
                    <div
                      className="background-preview"
                      style={{
                        background: themeImgURL && `url(${themeImgURL})`
                      }}
                    />
                    <span className="background-delete">
                      <Button
                        className="iconBtn"
                        icon={
                          <DeleteOutlined
                            style={{
                              fontSize: '18px',
                              color: '#E94A4A'
                            }}
                          />
                        }
                      />
                    </span>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Card>
        </>
      }
    />
  );
};
export default ThemeModal;
