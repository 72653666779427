import { Menu, Affix, Drawer, Avatar } from 'antd';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import { AppContext } from '../../AppContext';
import '../../styles/pages/userNavbar.less';
import { ROUTES } from '../../common/constant';
import { UserIcon, Logo } from '../../assets/index';
import Button from '../../components/Button/Button';

const { SubMenu } = Menu;

const AdminNavbar = () => {
  const [current, setCurrent] = useState('Dashboard');
  const [drawervisible, setDrawerVisible] = useState(false);
  const { dispatch, state, getToken } = useContext(AppContext);
  const currentUser = state?.userDetails;
  const idToken = getToken();
  const history = useHistory();

  const showDrawer = () => {
    setDrawerVisible(true);
  };
  const onClose = () => {
    setDrawerVisible(false);
  };

  const signout = () => {
    dispatch({ type: 'LOGOUT' });
    history.push('/');
  };

  const handleClick = (e) => {
    setCurrent(e.key);
    setDrawerVisible(false);
  };

  return (
    <>
      {idToken ? (
        <Affix offsetTop={0}>
          <div>
            <div className="user-navbar-container">
              <Link to={ROUTES.ADMIN_DASHBOARD} onClick={handleClick}>
                <img src={Logo} alt="logo" className="logo" />
              </Link>
              <div className="center menu">
                <Menu
                  onClick={handleClick}
                  selectedKeys={[current]}
                  mode="horizontal"
                >
                  <Menu.Item key="3">
                    <Link to={ROUTES.ADMIN_DASHBOARD}>Dashboard</Link>
                  </Menu.Item>
                </Menu>
              </div>
              <div className="right mobile-nav">
                <Menu mode="horizontal" onClick={handleClick}>
                  <SubMenu
                    key="sub1"
                    icon={
                      get(currentUser, ['profileImage']) !== null ? (
                        <Avatar
                          size={30}
                          src={get(currentUser, ['profileImage'])}
                          icon={<UserOutlined />}
                        />
                      ) : (
                        <Avatar className="defaultLogo" src={UserIcon} />
                      )
                    }
                  >
                    <Menu.Item key="1" onClick={signout}>
                      Logout
                    </Menu.Item>
                  </SubMenu>
                </Menu>
                <div className="responsive-menu">
                  <Button
                    className="iconBtn"
                    icon={<MenuOutlined />}
                    onClick={showDrawer}
                  />
                  <Drawer
                    title="1Lynk"
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    visible={drawervisible}
                  >
                    <Menu
                      className="vertical-menu"
                      onClick={handleClick}
                      selectedKeys={[current]}
                      mode="vertical"
                    >
                      <Menu.Item key="Dashboard">
                        <Link to="/dashboard">Dashboard</Link>
                      </Menu.Item>
                    </Menu>
                  </Drawer>
                </div>
              </div>
            </div>
          </div>
        </Affix>
      ) : null}
    </>
  );
};
export default AdminNavbar;
