// for future reference
import { useQuery } from '@apollo/client';
import { Spin, Table, Tag } from 'antd';
import React, { useState } from 'react';
import GET_USER_LIST from './graphql/query';

const UserListTable = ({ query }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const showRecordsPerPage = 5;
  const { loading, error, data = {} } = useQuery(GET_USER_LIST, {
    variables: {
      limit: showRecordsPerPage,
      page: currentPage,
      username: query,
      email: query
    }
  });

  if (loading) return <Spin size="small" className="mt-10" />;
  if (error) return <h1>Error :- {error.message}</h1>;

  // pagination
  const paginationHandler = (page) => {
    setCurrentPage(page);
  };

  const columns = [
    {
      key: 'id',
      title: 'Id',
      width: '9%',
      ellipsis: true,
      render: (user) => user?.id
    },
    {
      key: 'username',
      title: 'Username',
      dataIndex: 'username',
      ellipsis: true
    },
    {
      key: 'email',
      title: 'E-mail',
      dataIndex: 'email',
      ellipsis: true
    },
    {
      key: 'link',
      title: 'Link',
      dataIndex: 'link',
      ellipsis: true
    },
    {
      key: 'isActive',
      title: 'Active',
      dataIndex: 'isActive',
      ellipsis: true,
      render: (isActive) => (
        <Tag color={isActive ? 'green' : 'red'}>
          {isActive ? 'Active' : 'Not Active'}
        </Tag>
      )
    }
    // for future ref.
    // {
    //   title: 'Custom Appearance',
    //   key: 'isCustom',
    //   dataIndex: 'isCustom',
    //   ellipsis: true,
    //   render: (isCustom) => (
    //     <Tag color={isCustom ? 'purple' : 'red'}>{isCustom ? 'YES' : 'NO'}</Tag>
    //   )
    // }
  ];

  return (
    <Table
      columns={columns}
      className="table"
      dataSource={data?.userList?.data}
      loading={loading}
      pagination={{
        total: data?.userList?.totalRecords,
        pageSize: showRecordsPerPage,
        onChange: paginationHandler,
        current: currentPage
      }}
    />
  );
};

export default UserListTable;
