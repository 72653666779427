import React, { useRef } from 'react';
import { Form, Row, Col, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import Input from '../../components/Input/input';
import Button from '../../components/Button/Button';
import client from '../../apollo';
import { CHANGE_PASSWORD } from './graphql/Mutation';

const ChangePwd = () => {
  const pwdRef = useRef();

  const handleOk = (values) => {
    const { newpwd, oldpwd, confirm } = values;
    const pattern = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])'
    );
    if (!(pattern.test(newpwd) && pattern.test(confirm))) {
      message.error(
        'Must have at least one lowercase letter, one uppercase letter, one digit, one special character'
      );
    } else if (newpwd !== confirm) {
      message.error('Password and confirm password are not matching');
    } else {
      client
        .mutate({
          mutation: CHANGE_PASSWORD,
          variables: {
            oldpwd,
            newpwd,
            confirm
          }
        })
        .then(() => {
          message.success('Password updated');
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
    pwdRef.current.resetFields();
  };
  return (
    <>
      <Form
        ref={pwdRef}
        name="basic"
        initialValues={{
          remember: true
        }}
        autoComplete="false"
        onFinish={handleOk}
      >
        <Col>
          <Form.Item
            name="oldpwd"
            rules={[
              {
                required: true,
                message: 'Please input your old password!'
              }
            ]}
          >
            <Input
              addonBefore="Password"
              type="password"
              minLength="6"
              suffix={<LockOutlined className="color-grey" />}
              placeholder="Old Password"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="newpwd"
            rules={[
              {
                required: true,
                message: 'Please input your new password!'
              }
            ]}
          >
            <Input
              addonBefore="New"
              type="password"
              minLength="6"
              suffix={<LockOutlined className="color-grey" />}
              placeholder="New Password"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="confirm"
            rules={[
              {
                required: true,
                message: 'Please input your Confirm Password!'
              }
            ]}
          >
            <Input
              addonBefore="Confirm"
              type="password"
              minLength="6"
              suffix={<LockOutlined className="color-grey" />}
              placeholder="Confirm Password"
            />
          </Form.Item>
        </Col>
        <Row justify="end">
          <Col>
            <Form.Item className="mb-0">
              <Button
                className="btnPrimary"
                type="primary"
                shape="round"
                size="small"
                htmlType="submit"
              >
                Change
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default ChangePwd;
