import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constant';
import PrivateRoute from '../../PrivateRoute';
import Analytics from '../user/Analytics';
import Appearance from '../user/AppearanceTab/Appearance';
import AddLink from '../user/LinksTab/AddLink';
import Setting from '../user/Setting';
import UserProfile from '../user/UserProfile';

function Admin() {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.ADMIN}
        render={() => <Redirect to={ROUTES.LINKS} />}
      />
      <PrivateRoute path={ROUTES.LINKS} exact component={AddLink} />
      <PrivateRoute path={ROUTES.APPEARANCE} exact component={Appearance} />
      <PrivateRoute path={ROUTES.ANALYSIS} exact component={Analytics} />
      <PrivateRoute path={ROUTES.SETTINGS} exact component={Setting} />
      <PrivateRoute path={ROUTES.USER_PROFILE} component={UserProfile} />
    </Switch>
  );
}

export default Admin;
