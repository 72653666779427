import { useQuery } from '@apollo/client';
import { Col, Row } from 'antd';
import React from 'react';
import { GET_FONTS } from '../user/graphql/Query';
import Card from '../../components/Card/card';
import Loader from '../../components/Loader/Loader';
import 'antd/dist/antd.css';

function FontList({ fontStyleHandler, txtStyle }) {
  const { loading, data = {} } = useQuery(GET_FONTS, {
    fetchPolicy: 'network-only'
  });

  if (loading) return <Loader />;

  return (
    <>
      <Row gutter={[16, 28]} justify="center">
        {data &&
          data.fontList &&
          data.fontList.map((font) => {
            return (
              <div
                onClick={() => fontStyleHandler(font.fontStyle)}
                key={font.id}
              >
                <Col>
                  <Card
                    className={
                      txtStyle.fontFamily === font.fontStyle.fontFamily
                        ? 'selected-font-border fonts-modal'
                        : 'fonts-modal'
                    }
                  >
                    {data?.fontList && (
                      <span
                        style={{
                          fontSize: 16,
                          ...(font.fontStyle || {
                            fontFamily: 'monospace'
                          })
                        }}
                      >
                        1Lynk
                      </span>
                    )}
                  </Card>
                </Col>
              </div>
            );
          })}
      </Row>
    </>
  );
}
export default FontList;
