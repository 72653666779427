import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { LockOutlined, LoginOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Form, Layout, message, Row, Typography, Divider } from 'antd';
import { lynkLogo } from '../../assets/index';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import Input from '../../components/Input/input';
import client from '../../apollo';
import { AppContext } from '../../AppContext';
import { USER_LOGIN } from './graphql/Query';
import '../../styles/pages/auth.less';
import { ROUTES } from '../../common/constant';

const { Content } = Layout;
const { Title, Text } = Typography;

function Login() {
  const { dispatch, initializeAuth } = useContext(AppContext);
  const history = useHistory();
  // eslint-disable-next-line no-undef
  const idToken = localStorage.getItem('jwtToken');
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-undef
  const { search } = window?.location;
  const params = new URLSearchParams(search);
  const confirmEmailMsg = params.get('message');

  useEffect(() => {
    if (idToken) {
      history.push(ROUTES.ADMIN);
    }
    if (confirmEmailMsg) message.success(confirmEmailMsg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const layout = {
    labelCol: {
      span: 5
    },
    wrapperCol: {
      span: 17
    }
  };
  const onFinish = (values) => {
    setLoading(true);
    client
      .query({
        query: USER_LOGIN,
        variables: {
          ...values
        },
        fetchPolicy: 'network-only'
      })
      .then((resp) => {
        const { data = {} } = resp;
        const { loginUser = {} } = data;
        const { token } = loginUser;

        if (token) initializeAuth(token);
        dispatch({ type: 'SET_FETCHING_USER_DETAILS', data: loginUser });
        setLoading(false);
        if (loginUser?.roles[0] !== 'ADMIN') {
          history.push(ROUTES.ADMIN);
        } else {
          history.push(ROUTES.ADMIN_DASHBOARD);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <Row align="middle" justify="center" className="main-container">
      <Col xs={24} lg={12}>
        <Content className="login-container">
          <Row justify="center" gutter={{ xs: 3, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row">
              <Row align="middle" justify="center" className="mb-20">
                <Col>
                  <img className="lynk-logo" src={lynkLogo} alt="logo" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Col>

      <Col xs={24} lg={12} className="vertical-divider">
        <Content className="login-container">
          <Row justify="start" gutter={{ xs: 3, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row">
              <Card className="link-card">
                <Row className="mb-20">
                  <Col className="text-start">
                    <Title level={2} className="m-0 login-title">
                      Welcome Back,
                    </Title>
                    <Text className="login-text">
                      Please Sign In to your account
                    </Text>
                  </Col>
                </Row>
                <Form
                  name="basic"
                  initialValues={{
                    remember: true
                  }}
                  {...layout}
                  onFinish={onFinish}
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your username!'
                      }
                    ]}
                  >
                    <Input
                      addonBefore="Username"
                      suffix={<UserOutlined className="site-form-item-icon" />}
                      placeholder="Username / Email"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!'
                      }
                    ]}
                  >
                    <Input
                      type="password"
                      addonBefore="Password"
                      placeholder="Password"
                      suffix={<LockOutlined className="site-form-item-icon" />}
                    />
                  </Form.Item>
                  <Row justify="space-between" align="middle" className="mt-20">
                    <Button
                      type="primary"
                      loading={loading}
                      icon={<LoginOutlined />}
                      htmlType="submit"
                      size="small"
                      shape="round"
                    >
                      Sign In
                    </Button>
                    <Link to="/forgot-password">Forget Password?</Link>
                  </Row>
                </Form>
              </Card>
              <Divider className="horizontal-divider">OR</Divider>
              <Row justify="center" className="mt-20">
                <Link to="/register">New to 1Lynk ? Register Here</Link>
              </Row>
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
}
export default Login;
