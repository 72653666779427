import React, { useState } from 'react';
import { debounce } from 'lodash';
import { Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Input from '../../components/Input/input';
import UserListTable from './UserListTable';

const UserList = () => {
  const [query, setQuery] = useState('');

  const onSearchInputHandler = debounce((e) => {
    setQuery(e.target.value);
  }, 1000);

  return (
    <div>
      <Row justify="end" className="mb-20">
        <Col>
          <Input
            placeholder="Search"
            onChange={onSearchInputHandler}
            suffix={<SearchOutlined />}
          />
        </Col>
      </Row>
      <UserListTable query={query} />
    </div>
  );
};
export default UserList;
