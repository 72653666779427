import React from 'react';
import { Row, Col } from 'antd';

function ComingSoon() {
  return (
    <>
      <Row justify="center">
        <Col>
          <p className="text-center fs-30">Coming soon...</p>
        </Col>
      </Row>
    </>
  );
}

export default ComingSoon;
