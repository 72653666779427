import { gql } from '@apollo/client';

const GET_USER_LIST = gql`
  query userList($limit: Int, $page: Int, $username: String, $email: String) {
    userList(
      input: { limit: $limit, page: $page }
      searchQuery: { username: $username, email: $email }
    ) {
      data {
        id
        username
        email
        link
        profileImage
        isCustom
        isActive
      }
      totalRecords
    }
  }
`;

export default GET_USER_LIST;
