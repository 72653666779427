import { gql } from '@apollo/client';

const GET_FEATURE = gql`
  {
    featureList {
      id
      title
      description
      isActive
    }
  }
`;
export default GET_FEATURE;
