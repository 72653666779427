import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import client from './apollo';
import { AppContext } from './AppContext';
import { GET_CURRENT_USER } from './modules/auth/graphql/Query';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { dispatch, getToken } = useContext(AppContext);
  const idToken = getToken();

  useEffect(() => {
    if (idToken) {
      client
        .query({
          query: GET_CURRENT_USER
        })
        .then((resp) => {
          const { data = {} } = resp;
          const { findUser = {} } = data;
          dispatch({ type: 'SET_FETCHING_USER_DETAILS', data: findUser });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        !idToken ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  );
};
export default PrivateRoute;
