import { LockOutlined } from '@ant-design/icons';
import { Col, Form, Layout, message, Row, Typography } from 'antd';
import axios from 'axios';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../common/constant';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import Input from '../../components/Input/input';
import { lynkLogo } from '../../assets/index';

const { Content } = Layout;
const { Title } = Typography;

const ResetPwd = (props) => {
  const { location } = props;
  const { pathname = ROUTES.MAIN } = location;
  const history = useHistory();
  const handleOk = (values) => {
    const pattern = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])'
    );
    if (values.password !== values.confirmPassword) {
      message.error('Password and confirm password are not matching');
    } else if (
      !(pattern.test(values.password) && pattern.test(values.confirmPassword))
    ) {
      message.error(
        'Must have at least one lowercase letter, one uppercase letter, one digit, one special character'
      );
    } else if (pathname) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}${pathname}`, values)
        .then((res) => {
          const { data = {} } = res;
          if (data?.message === 'success') {
            message.success('Your password is reset successfully.');
            history.push('/login');
          } else {
            message.error('failed');
          }
        });
    }
  };
  return (
    <Content className="login-container">
      <Row justify="center" gutter={{ xs: 2, sm: 16, md: 24, lg: 32 }}>
        <Title level={2}>Reset Password</Title>
      </Row>
      <Row
        justify="center"
        align="middle"
        gutter={{ xs: 3, sm: 16, md: 24, lg: 32 }}
      >
        <Col className="gutter-row">
          <Card className="link-card">
            <Row align="middle" justify="center" className="mb-20">
              <Col>
                <img className="logo_img" src={lynkLogo} alt="" logo />
              </Col>
            </Row>
            <Form
              name="basic"
              initialValues={{
                remember: true
              }}
              autoComplete="false"
              onFinish={handleOk}
            >
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!'
                  }
                ]}
              >
                <Input
                  addonBefore="Password"
                  type="password"
                  minLength="6"
                  suffix={<LockOutlined className="color-grey" />}
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Confirm Password!'
                  }
                ]}
              >
                <Input
                  addonBefore="Confirm"
                  type="password"
                  minLength="6"
                  suffix={<LockOutlined className="color-grey" />}
                  placeholder="Confirm Password"
                />
              </Form.Item>
              <Form.Item className="mb-0">
                <center>
                  <Button
                    htmlType="submit"
                    shape="round"
                    className="btnPrimary"
                    type="primary"
                    size="small"
                  >
                    Change
                  </Button>
                </center>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </Content>
  );
};
export default ResetPwd;
