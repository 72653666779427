import React, { useContext, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constant';
import ForgetPwd from './modules/auth/ForgetPwd';
import Login from './modules/auth/Login';
import Registration from './modules/auth/Registration';
import ResetPwd from './modules/auth/ResetPwd';
import Success from './modules/auth/Success';
import SuperAdmin from './modules/Dashboard/SupeAdmin';
import Plans from './modules/TestimonialModules/Plans';
import SharableLink from './modules/user/SharableLink';
import UserNavbar from './modules/user/UserNavbar';
import Admin from './modules/admin/index';
import history from './historyData';
import AdminNavbar from './modules/admin/AdminNavbar';

function Routes() {
  const { initializeAuth, state } = useContext(AppContext);
  const currentUser = state?.userDetails;
  const { getToken } = useContext(AppContext);
  const idToken = getToken();

  useEffect(() => {
    initializeAuth();
    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Router>
        {idToken &&
          currentUser?.username &&
          currentUser?.roles[0] === 'USER' &&
          history.location.pathname !== `/${currentUser?.username}` && (
            <UserNavbar />
          )}
        <Switch>
          <Route
            exact
            path={ROUTES.MAIN}
            render={() => <Redirect to={ROUTES.LOGIN} />}
          />
          <Route path={ROUTES.PLANS_PRICING} exact component={Plans} />
          <Route path={ROUTES.LOGIN} exact component={Login} />
          <Route path={ROUTES.SIGNUP} exact component={Registration} />
          <Route path={ROUTES.RESET} exact component={ForgetPwd} />
          <Route path={ROUTES.CHANGE} component={ResetPwd} />
          <Route path={ROUTES.ADMIN} component={Admin} />
          {idToken &&
            currentUser?.username &&
            currentUser?.roles[0] === 'ADMIN' && (
              <>
                <AdminNavbar />
                <Route path={ROUTES.ADMIN_DASHBOARD} component={SuperAdmin} />
              </>
            )}
          <Route path={ROUTES.SHAREABLE_LINK} exact component={SharableLink} />
          <Route path={ROUTES.SUCCESS} exact component={Success} />
        </Switch>
      </Router>
    </div>
  );
}
export default Routes;
