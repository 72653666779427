import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Col, Row, message, Button, Popconfirm } from 'antd';
import Meta from 'antd/lib/card/Meta';
import client from '../../../apollo';
import { AppContext } from '../../../AppContext';
import { GET_CURRENT_USER } from '../../auth/graphql/Query';
import { UPDATE_USER, UPDATE_USERTHEME } from '../graphql/Mutation';
import { GET_THEMES } from '../graphql/Query';
import Card from '../../../components/Card/card';
import defaultBg from '../../../assets/images/defaultBg.jpg';
import Loader from '../../../components/Loader/Loader';
import 'antd/dist/antd.css';

function ThemeList() {
  const { state, dispatch } = useContext(AppContext);
  const currentUser = state.userDetails;

  const { loading, data = {} } = useQuery(GET_THEMES, {
    fetchPolicy: 'network-only'
  });

  const onClick = (themeId) => {
    if (currentUser?.isCustom) {
      client
        .mutate({
          mutation: UPDATE_USER,
          variables: {
            id: currentUser?.id,
            isCustom: false
          },
          refetchQueries: [{ query: GET_CURRENT_USER }]
        })
        .then((res) => {
          dispatch({
            type: 'SET_FETCHING_USER_DETAILS',
            data: {
              ...currentUser,
              isCustom: res?.data?.updateUser?.isCustom
            }
          });
        })
        .catch(() => {
          message.error('look like we got some problem');
        });
    }
    client
      .mutate({
        mutation: UPDATE_USERTHEME,
        variables: {
          id: themeId
        },
        refetchQueries: [{ query: GET_CURRENT_USER }]
      })
      .then((res) => {
        dispatch({
          type: 'SET_FETCHING_USER_DETAILS',
          data: {
            ...currentUser,
            theme: res?.data?.updateUserTheme?.theme,
            isCustom: false
          }
        });
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="align-center">
        <p className="font-appearance">Themes</p>
      </div>
      <Row gutter={[48, 16]} justify="center" className="container-fluid mt-36">
        {data &&
          data.themeList &&
          data.themeList.map((theme) => {
            const selectedTheme =
              theme?.id === currentUser?.theme?.id && !currentUser?.isCustom;
            return (
              <Col
                key={theme.id}
                onClick={() => !currentUser?.isCustom && onClick(theme.id)}
              >
                <div className={selectedTheme ? 'selected-theme-border' : ''}>
                  <Card
                    hoverable
                    className="appearance-card"
                    cover={
                      theme?.image ? (
                        <>
                          <img
                            className="theme-img"
                            height="250px"
                            alt={theme?.title}
                            src={theme?.image || defaultBg}
                          />
                          <div
                            className={
                              !currentUser?.isCustom ? 'apply-btn' : ''
                            }
                          >
                            {currentUser?.isCustom && (
                              <div className="theme-btn">
                                <Popconfirm
                                  title="Are you sure you want to change theme?"
                                  onConfirm={() => onClick(theme?.id)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button size="small" shape="round">
                                    Apply
                                  </Button>
                                </Popconfirm>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <div
                          className="themeCards"
                          style={theme?.backgroundStyle}
                        >
                          <div
                            className={
                              !currentUser?.isCustom ? 'apply-btn' : ''
                            }
                          >
                            {currentUser?.isCustom && (
                              <div>
                                <Popconfirm
                                  title="Are you sure you want to change theme?"
                                  onConfirm={() => onClick(theme?.id)}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button size="small" shape="round">
                                    Apply
                                  </Button>
                                </Popconfirm>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    }
                  >
                    <Meta title={theme?.title || '-'} />
                  </Card>
                </div>
              </Col>
            );
          })}
      </Row>
    </>
  );
}
export default ThemeList;
