import { useLazyQuery } from '@apollo/client';
import {
  DatePicker,
  Typography,
  Form,
  message,
  Switch,
  Select,
  Col,
  Row
} from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import Modal from '../../../components/Modal/Modal';
import Input from '../../../components/Input/input';
import client from '../../../apollo';
import { AppContext } from '../../../AppContext';
import Button from '../../../components/Button/Button';
import { GET_CURRENT_USER } from '../../auth/graphql/Query';
import MODIFY_LINK, {
  CREATE_LINK,
  SET_CUSTOM_APPERAENCE
} from '../graphql/Mutation';
import GET_LINKS, {
  GET_LINK,
  GET_SCHEDULE_LINK,
  GET_TYPES
} from '../graphql/Query';
import '../../../styles/pages/addLinks.less';
import { Caret, Calender } from '../../../assets/index';

const { Paragraph } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const AddLinkModal = ({
  addLinkModalHandler,
  isModalVisible,
  edit,
  setRemove
}) => {
  // fetch current user from context
  const { state, dispatch } = useContext(AppContext);
  const currentUser = state.userDetails;

  // state
  const [selectType, setSelectType] = useState('URL');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [pageId, setPageId] = useState(0);
  const [editType, setEditType] = useState();

  const [form] = Form.useForm();

  // query
  const [
    getOptionType,
    { data: LinkTypes = {}, loading, error }
  ] = useLazyQuery(GET_TYPES, {
    fetchPolicy: 'cache-and-network'
  });

  const [getLink, { data: linkData = {}, loading: editLoading }] = useLazyQuery(
    GET_LINK,

    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        form.setFieldsValue({
          title: res?.findUserLinkList?.title,
          value: res?.findUserLinkList?.value
        });
        setEditType(
          res?.findUserLinkList?.type === 'CONTENT'
            ? 'string'
            : res?.findUserLinkList?.type.toLowerCase()
        );
      }
    }
  );

  // form ref
  useEffect(() => {
    if (currentUser) {
      const { page } = currentUser;
      if (page[0]?.id) {
        const { id: pid } = page[0];
        setPageId(pid);
      }
      client
        .query({
          query: GET_SCHEDULE_LINK,
          variables: {
            custom: true
          }
        })
        .then(() => {})
        .catch((err) => {
          message.error(err.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // form layout
  const layout = {
    labelCol: {
      span: 5
    },
    wrapperCol: {
      span: 16
    }
  };

  // modal handlers
  const showModal = (editId) => {
    form.resetFields();
    if (editId) {
      getLink({
        variables: {
          id: editId
        }
      });
    }
    // fetch all options from server when user click on Add btn
    getOptionType();
    addLinkModalHandler(true);
  };

  const handleOk = (values) => {
    let from = null;
    let to = null;

    setShowDatePicker(false);

    if (values?.time) {
      from = values?.time[0];
      to = values?.time[1];
    }

    if (edit) {
      client
        .mutate({
          mutation: MODIFY_LINK,
          variables: {
            id: edit,
            title: values.title,
            value: values.value,
            from,
            to
          }
        })
        .then(() => {
          message.success('Link is updated successfully');
          // close the modal on submit event
          addLinkModalHandler(false);
        })
        .catch(() => {});
    } else if (currentUser?.isCustom) {
      client
        .mutate({
          mutation: CREATE_LINK,
          variables: {
            title: values.title,
            type: selectType,
            value: values.url || values.mail || values.content,
            from,
            to
          },
          refetchQueries: [
            { query: GET_LINKS, variables: { custom: currentUser.isCustom } },
            {
              query: GET_SCHEDULE_LINK,
              variables: { custom: currentUser?.isCustom }
            }
          ]
        })
        .then((res) => {
          const { data = {} } = res;
          const { createUserLink = {} } = data;
          client
            .mutate({
              mutation: SET_CUSTOM_APPERAENCE,
              variables: {
                userlinkid: createUserLink?.id,
                pageid: pageId
              },
              refetchQueries: [{ query: GET_CURRENT_USER }]
            })
            .then(() => {
              dispatch({
                type: 'SET_FETCHING_USER_DETAILS',
                data: {
                  ...currentUser,
                  userLink: [
                    ...currentUser?.userLink,
                    res?.data?.createUserLink
                  ]
                }
              });
              // close the modal on submit event
              addLinkModalHandler(false);
              form.resetFields();
            })
            .catch(() => {});
        })
        .catch(() => {});
    } else {
      client
        .mutate({
          mutation: CREATE_LINK,
          variables: {
            title: values.title,
            type: selectType,
            value: values.url || values.mail || values.content,
            from,
            to
          },
          refetchQueries: [
            { query: GET_LINKS, variables: { custom: currentUser?.isCustom } },
            {
              query: GET_SCHEDULE_LINK,
              variables: { custom: currentUser?.isCustom }
            }
          ]
        })
        .then((res) => {
          dispatch({
            type: 'SET_FETCHING_USER_DETAILS',
            data: {
              ...currentUser,
              userLink: [...currentUser?.userLink, res?.data?.createUserLink]
            }
          });

          addLinkModalHandler(false);
          form.resetFields();
        })
        .catch(() => {});
    }
  };

  const handleCancel = () => {
    form.resetFields();
    addLinkModalHandler(false);
    setShowDatePicker(false);
  };

  // select linktype handler
  const handleSelect = (value) => {
    setSelectType(value);
  };

  // schedule change checkbox handler
  const scheduleChangeHandler = () => {
    // eslint-disable-next-line no-unused-expressions
    setShowDatePicker(!showDatePicker);
  };

  // select
  const selectBefore = (
    <Select
      style={{ width: 100 }}
      onChange={handleSelect}
      suffixIcon={<img src={Caret} alt="arrow" />}
      defaultValue={selectType}
    >
      {error && <Option>{error.message}</Option>}
      {loading ? (
        <Option>Loading...</Option>
      ) : (
        LinkTypes?.typeList?.map((type) => {
          return (
            <Option key={type.id} value={type.key}>
              {type.value}
            </Option>
          );
        })
      )}
    </Select>
  );
  return (
    <Row justify="center">
      {edit ? (
        <Button
          className="btnLight"
          block
          size="small"
          shape="round"
          onClick={() => {
            showModal(edit);
            setRemove(false);
          }}
        >
          Edit
        </Button>
      ) : (
        <Button
          className="btnPrimary mb-20"
          block
          type="primary"
          shape="round"
          onClick={() => showModal()}
        >
          Add New Link
        </Button>
      )}
      {!editLoading && (
        <Modal
          className="modal"
          closable={false}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Form
              className="form"
              form={form}
              name="basic"
              layout="horizontal"
              initialValues={{}}
              autoComplete="false"
              onFinish={handleOk}
            >
              <Form.Item className="modalFooter">
                <Button
                  className="btnLink"
                  key="submit"
                  type="link"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="btnSecondary"
                  size="small"
                  shape="round"
                  htmlType="submit"
                >
                  {edit ? 'Update' : 'Add'}
                </Button>
              </Form.Item>
            </Form>
          ]}
          content={
            <Form
              form={form}
              name="basic"
              {...layout}
              autoComplete="false"
              onFinish={handleOk}
            >
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Title is required!'
                  }
                ]}
              >
                <Input addonBefore="Title" placeholder="Title" />
              </Form.Item>
              {!edit ? (
                <>
                  {selectType === 'URL' && (
                    <Form.Item
                      name="url"
                      rules={[
                        {
                          required: true,
                          message: 'Url is required!'
                        }
                      ]}
                    >
                      <Input
                        addonBefore={selectBefore}
                        placeholder="https://Yoursite.com"
                      />
                    </Form.Item>
                  )}

                  {selectType === 'EMAIL' && (
                    <Form.Item
                      name="mail"
                      rules={[
                        {
                          required: true,
                          message: 'Email is required!'
                        }
                      ]}
                    >
                      <Input
                        type="email"
                        addonBefore={selectBefore}
                        placeholder="google@gmail.com"
                      />
                    </Form.Item>
                  )}

                  {selectType === 'CONTENT' && (
                    <Form.Item
                      name="content"
                      rules={[
                        {
                          required: true,
                          message: 'Content is required!'
                        }
                      ]}
                    >
                      <Input addonBefore={selectBefore} placeholder="Content" />
                    </Form.Item>
                  )}
                </>
              ) : (
                <Form.Item
                  className="mb-0"
                  name="value"
                  rules={[
                    {
                      type: editType
                    }
                  ]}
                >
                  <Input
                    addonBefore={
                      linkData?.findUserLinkList?.type.substr(0, 1) +
                      linkData?.findUserLinkList?.type.toLowerCase().slice(1)
                    }
                  />
                </Form.Item>
              )}
              <Row align="middle">
                {!edit && (
                  <>
                    <Col>
                      <Form.Item className="modalFooter" name="scheduleChanger">
                        <Switch
                          checked={showDatePicker}
                          onClick={scheduleChangeHandler}
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Paragraph className="label">Schedule</Paragraph>
                    </Col>
                  </>
                )}
              </Row>
              <Col span={24}>
                {showDatePicker && (
                  <Form.Item name="time" className="modalFooter mt-14">
                    <RangePicker
                      use12Hours
                      suffixIcon={<img src={Calender} alt="arrow" />}
                      disabledDate={(current) => {
                        return moment().add(-1, 'days') >= current;
                      }}
                      className="rangePicker"
                      showTime
                    />
                  </Form.Item>
                )}
              </Col>
            </Form>
          }
        />
      )}
    </Row>
  );
};

export default AddLinkModal;
