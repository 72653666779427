import React, { useEffect, useRef, useState } from 'react';
import { CheckOutlined, CloseOutlined, LockOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Col, Empty, Form, Row, Space, Switch, Tag, Typography } from 'antd';
import client from '../../../apollo';
import { GET_CURRENT_USER } from '../../auth/graphql/Query';
import { SET_CUSTOM_APPERAENCE } from '../graphql/Mutation';
import GET_LINKS, { GET_BUTTONS } from '../graphql/Query';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Modal/Modal';
import Loader from '../../../components/Loader/Loader';
import 'antd/dist/antd.css';

const { Text } = Typography;

function ButtonList() {
  const buttonRef = useRef();
  const [checkcustom, setCheckcustom] = useState(false);
  const [btnId, setBtnId] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data: userData = {} } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (userData && userData.findUser) {
      const { findUser: { isCustom: check } = {} } = userData;
      setCheckcustom(check);
    }
  }, [userData]);
  const { data: linkData = {} } = useQuery(GET_LINKS, {
    variables: {
      custom: checkcustom || false
    }
  });

  const { loading: buttonLoading, data: buttons = {} } = useQuery(GET_BUTTONS, {
    fetchPolicy: 'network-only'
  });
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    buttonRef.current.resetFields();
  };

  const onchange = (switchOn, linkId) => {
    client
      .mutate({
        mutation: SET_CUSTOM_APPERAENCE,
        variables: {
          userlinkid: linkId,
          btnid: switchOn ? btnId : 1
        },
        refetchQueries: [
          { query: GET_LINKS, variables: { custom: checkcustom } },
          { query: GET_CURRENT_USER }
        ]
      })
      .then(() => {})
      .catch(() => {});
  };

  if (buttonLoading) return <Loader />;

  return (
    <>
      <div className="align-center">
        <p className="font-appearance">
          Buttons{' '}
          <Tag color="#5454ef">
            PRO <LockOutlined />
          </Tag>
        </p>
      </div>
      <Row
        gutter={[16, 28]}
        justify="center"
        className=" container-fluid mt-36"
      >
        {buttons &&
          buttons.buttonList &&
          buttons.buttonList.map((button) => {
            const btnStyle = {
              ...(button.buttonStyle || {}),
              maxWidth: '100vh',
              width: 200
            };
            return (
              <div onClick={() => setBtnId(button.id)} key={button.id}>
                <Col key={button.id}>
                  <Button size="large" onClick={showModal} style={btnStyle}>
                    1Lynk
                  </Button>
                </Col>
              </div>
            );
          })}
      </Row>
      <Modal
        title="Select Buttons"
        key={btnId}
        closable={false}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button
            className="btnSecondary"
            type="secondry"
            size="small"
            shape="round"
            key="submit"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        ]}
        content={
          <Form
            ref={buttonRef}
            name="buttonModal"
            initialValues={{
              remember: true
            }}
            autoComplete="false"
          >
            {linkData &&
            linkData.userLinkList &&
            linkData.userLinkList.length > 0 ? (
              linkData.userLinkList.map((link) => {
                if (link?.isActive && link?.type !== 'CONTENT') {
                  return (
                    <Form.Item className="btn-switch" name="link" key={link.id}>
                      <Space>
                        <Row>
                          <Col className="mr-10">
                            <Switch
                              checkedChildren={<CheckOutlined />}
                              unCheckedChildren={<CloseOutlined />}
                              onChange={(e) => onchange(e, link.id)}
                              defaultChecked={
                                // eslint-disable-next-line no-unneeded-ternary
                                link?.customAppearance?.button?.id === btnId
                                  ? true
                                  : false
                              }
                            />
                          </Col>
                          <Text>{link.title || '-'}</Text>
                        </Row>
                      </Space>
                    </Form.Item>
                  );
                }
                return null;
              })
            ) : (
              <Empty />
            )}
          </Form>
        }
      />
    </>
  );
}
export default ButtonList;
