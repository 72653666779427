import React, { useContext, useState } from 'react';
import { Image, Row, Typography, Col } from 'antd';
import { SortableContainer } from 'react-sortable-hoc';
import { useQuery } from '@apollo/client';
import { Empty } from '../../../assets/index';
import SortableItem from './SortableItem';
import { GET_SCHEDULE_LINK } from '../graphql/Query';
import { AppContext } from '../../../AppContext';
import AddLinkModal from './AddLinkModal';

const { Paragraph } = Typography;

export const SortableList = SortableContainer(() => {
  // fetch current user from context
  const { state } = useContext(AppContext);
  const currentUser = state.userDetails;
  const [isModalVisible, setIsModalVisible] = useState(false);

  // add link modal handler
  const addLinkModalHandler = (isShow) => {
    setIsModalVisible(isShow);
  };

  const { data: items = {} } = useQuery(GET_SCHEDULE_LINK, {
    variables: {
      custom: currentUser?.isCustom || false
    },
    fetchPolicy: 'network-only'
  });

  return (
    <div className="mb-30">
      {items &&
        items?.userLinkListDisplaysScheduling &&
        items?.userLinkListDisplaysScheduling.length > 0 &&
        items?.userLinkListDisplaysScheduling.map((link = {}) => (
          <div key={`item-${link.id}`}>
            <SortableItem
              key={`item-${link.id}`}
              index={link.position}
              value={link}
            />
          </div>
        ))}
      {items?.userLinkListDisplaysScheduling?.length === 0 && (
        <>
          <div className="mt-50">
            <Row justify="center" align="middle">
              <Col>
                <Image src={Empty} preview={false} />
              </Col>
            </Row>
            <Row justify="center" align="middle">
              <Col>
                <Paragraph className="empty-text">
                  No links added yet!
                </Paragraph>
              </Col>
            </Row>
            <Row justify="center" align="middle">
              <Col>
                <AddLinkModal
                  addLinkModalHandler={addLinkModalHandler}
                  isModalVisible={isModalVisible}
                />
              </Col>
            </Row>
          </div>
        </>
      )}
    </div>
  );
});

export default SortableList;
