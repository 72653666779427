import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Col,
  Form,
  message,
  Popconfirm,
  Row,
  Spin,
  Layout,
  Upload
} from 'antd';
import 'antd/dist/antd.css';
import axios from 'axios';
import { get } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import client from '../../../apollo';
import { AppContext } from '../../../AppContext';
import Phone from '../../../components/Phone';
import { GENERATE_S3_SIGNED_URL } from '../../admin/graphql/mutation';
import { GET_CURRENT_USER } from '../../auth/graphql/Query';
import { UPDATE_USER } from '../graphql/Mutation';
import BackgroundList from './BackgroundList';
import ButtonList from './ButtonList';
import FontList from './FontList';
import Button from '../../../components/Button/Button';
import Card from '../../../components/Card/card';
import Modal from '../../../components/Modal/Modal';
import HelpButton from '../../../components/HelpButton';
import '../../../styles/pages/appearance.less';
import ThemeList from './ThemeList';
import {
  FILE_SIZE_UNIT,
  MAX_FILE_SIZE,
  MAX_FILE_SIZE_ERROR_MESSAGE
} from '../../../common/constant';

const { Content } = Layout;

function Appearance() {
  const { state, dispatch } = useContext(AppContext);
  const currentUser = state?.userDetails;
  const [firstLetter, setFirstLetter] = useState('');
  const formRef = useRef();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [user, setUser] = useState({});
  const [form] = Form.useForm();
  const [fileDetails, setFileDetails] = useState({});
  const [fileLength, setFileLength] = useState();

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
      const { username = '-' } = currentUser;
      if (username) {
        // eslint-disable-next-line no-shadow
        const firstLetter =
          username?.toString()?.charAt(0)?.toUpperCase() || '-';
        setFirstLetter(firstLetter);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const removePic = () => {
    client
      .mutate({
        mutation: UPDATE_USER,
        variables: {
          id: user?.id,
          profileImage: null
        }
      })
      .then((res) => {
        if (res?.data?.updateUser?.profileImage === null) {
          // if profile is removed then update app context
          const updatedUser = {
            ...currentUser,
            profileImage: null
          };
          setFileDetails('');
          dispatch({ type: 'SET_FETCHING_USER_DETAILS', data: updatedUser });
        }

        message.success('Details updated');
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const uploadToS3 = async (file, signedRequest) => {
    const options = {
      headers: {
        'Content-Type': file.type
      }
    };
    await axios({
      method: 'PUT',
      url: signedRequest,
      data: file,
      headers: options
    })
      .then((res) => {
        if (res?.status === 200) {
          client
            .query({
              query: GET_CURRENT_USER
            })
            .then((resp) => {
              const { data = {} } = resp;
              const { findUser = {} } = data;
              const updatedUser = {
                ...currentUser,
                profileImage: findUser.profileImage
              };
              dispatch({
                type: 'SET_FETCHING_USER_DETAILS',
                data: updatedUser
              });
            });
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const formatFilename = (filename, uid) => {
    const date = moment().format('YYYYMMDDHHmmssSSS');
    const newFilename = `profile-pictures/${date}-${uid}-${filename}`;
    return newFilename;
  };

  const beforeUpload = (file) => {
    const {
      // eslint-disable-next-line no-empty-pattern
      file: {}
    } = file;
    setFileDetails(file);
  };

  const uploadImage = (e) => {
    setFileDetails(e.file);
    // eslint-disable-next-line no-unneeded-ternary
    setFileLength(e.fileList.length > 0 ? true : false);
  };

  const handleOk = () => {
    if (!fileLength) {
      message.error('Please select profile image');
    } else if (fileDetails?.size / FILE_SIZE_UNIT > MAX_FILE_SIZE) {
      message.error(MAX_FILE_SIZE_ERROR_MESSAGE);
    } else {
      form.resetFields();
      client
        .mutate({
          mutation: GENERATE_S3_SIGNED_URL,
          variables: {
            fileName: formatFilename(fileDetails?.name, fileDetails?.uid),
            fileType: fileDetails?.type
          }
        })
        .then((resp) => {
          const { signedRequest, url } = resp?.data?.signS3;
          uploadToS3(fileDetails, signedRequest);
          if (url) {
            client
              .mutate({
                mutation: UPDATE_USER,
                variables: {
                  id: user?.id,
                  profileImage: url
                },
                refetchQueries: [{ query: GET_CURRENT_USER }]
              })
              .then(() => {
                message.success('Profile picture uploaded');
              })
              .catch((error) => {
                message.error(error.message);
              });
          }
        })
        .catch((error) => {
          message.error(error.message);
        });
      setIsModalVisible(false);
      formRef.current.resetFields();
    }
  };
  const handleCancel = () => {
    setIsModalVisible(false);
    formRef.current.resetFields();
  };
  const onRemove = () => {
    setFileDetails({});
  };
  return (
    <>
      {!user ? (
        <center>
          <Spin size="large" />
        </center>
      ) : (
        <Content className="content">
          <Row justify="space-between" className="appearance-wrapper">
            <Col order={1} flex="auto" className="appearance-list">
              <Row justify="center" className="mt-20">
                <Col>
                  <Card title="Profile Image" className="link-card">
                    <Row className="mb-0" align="middle">
                      <Col>
                        {get(user, ['profileImage']) !== null ? (
                          <Avatar
                            size={100}
                            src={get(user, ['profileImage'])}
                            icon={<UserOutlined />}
                          />
                        ) : (
                          <Avatar size={80} className="avatar">
                            {firstLetter}
                          </Avatar>
                        )}
                      </Col>
                      <Col flex="auto">
                        <Row justify="end">
                          <Col span={11} className="mr-8">
                            <Button
                              className="btnLight"
                              block
                              size="small"
                              shape="round"
                              onClick={showModal}
                            >
                              Edit
                            </Button>
                          </Col>
                          <Col span={11}>
                            <Popconfirm
                              title="Are you sure you want to remove profile picture?"
                              onConfirm={removePic}
                              disabled={
                                // eslint-disable-next-line no-unneeded-ternary
                                get(user, ['profileImage']) !== null
                                  ? false
                                  : true
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                disabled={
                                  // eslint-disable-next-line no-unneeded-ternary
                                  get(user, ['profileImage']) !== null
                                    ? false
                                    : true
                                }
                                block
                                type="danger"
                                shape="round"
                                size="small"
                              >
                                Remove
                              </Button>
                            </Popconfirm>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row justify="center" className="mt-36">
                <Col span={15}>
                  <ThemeList />
                </Col>
              </Row>
              <Row justify="center" className="mt-36">
                <Col span={15}>
                  <BackgroundList />
                </Col>
              </Row>
              <Row justify="center" className="mt-36">
                <Col span={15}>
                  <ButtonList />
                </Col>
              </Row>
              <Row justify="center" className="mt-36">
                <Col span={15}>
                  <FontList />
                </Col>
              </Row>
              <Modal
                title="Profile Image"
                visible={isModalVisible}
                closable={false}
                onCancel={handleCancel}
                footer={
                  <Row align="middle" justify="end">
                    <Col>
                      <Button
                        key="submit"
                        className="btnLink"
                        type="link"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Form
                        ref={formRef}
                        name="basic"
                        autoComplete="false"
                        onFinish={handleOk}
                      >
                        <Form.Item required className="mb-0">
                          <Button
                            className="btnSecondary"
                            size="small"
                            shape="round"
                            htmlType="submit"
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                }
                content={
                  <Form form={form} name="basic" autoComplete="false">
                    <Form.Item required name="upload" className="mb-0">
                      <Row align="middle" justify="center">
                        <Upload
                          onChange={uploadImage}
                          beforeUpload={beforeUpload}
                          className="upload"
                          listType="picture"
                          maxCount={1}
                          onRemove={onRemove}
                          accept="image/*"
                        >
                          <Button icon={<UploadOutlined />}>
                            Click to upload
                          </Button>
                        </Upload>
                      </Row>
                    </Form.Item>
                  </Form>
                }
              />
            </Col>
            <Col className="phoneContainer" order={2}>
              <Row justify="center" align="middle">
                <Col className="device-wrapper">
                  <Phone />
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      )}
      <HelpButton />
    </>
  );
}
export default Appearance;
