import {
  Alert,
  Col,
  Form,
  message,
  Row,
  Switch,
  Tooltip,
  Typography,
  Image
} from 'antd';
import React, { useContext, useState } from 'react';
import { SortableElement, sortableHandle } from 'react-sortable-hoc';
import { Sorter, RoundArrow } from '../../../assets';
import Button from '../../../components/Button/Button';
import client from '../../../apollo';
import { AppContext } from '../../../AppContext';
import { REGEX } from '../../../common/constant';
import Card from '../../../components/Card/card';
import MODIFY_LINK, { DELETE_USERLINK } from '../graphql/Mutation';
import GET_LINKS, { GET_SCHEDULE_LINK } from '../graphql/Query';
import TimeScheduleModal from './TimeScheduleModal';
import '../../../styles/pages/addLinks.less';
import AddLinkModal from './AddLinkModal';
import getTime from '../../../common/utils';

const { Paragraph } = Typography;

const SortableItem = SortableElement(({ value }) => {
  const DragHandle = sortableHandle(() => (
    <img className="drag" src={Sorter} alt="icon" />
  ));
  // state
  const [errorId, setErrorId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isTimeModalVisible, setIsTimeModalVisible] = useState(false);
  const [remove, setRemove] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // fetch current user from context
  const { state, dispatch } = useContext(AppContext);
  const currentUser = state.userDetails;

  // add link modal handler
  const addLinkModalHandler = (isShow) => {
    setIsModalVisible(isShow);
  };
  // update url title
  const onTitleBlur = (id) => (event) => {
    client
      .mutate({
        mutation: MODIFY_LINK,
        variables: {
          id: id,
          title: event.target.value
        },
        update: (cache, { data = {} }) => {
          // eslint-disable-next-line no-shadow
          const id = cache.identify(
            data && data.updateUserLink && data.updateUserLink.id
          );
          cache.modify({
            fields: {
              title(existingTitleRefs, { readField }) {
                return [...existingTitleRefs, readField(id)];
              }
            }
          });
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateUserLink: {
            __typename: 'UserLink',
            id: id,
            title: event.target.value
          }
        }
      })
      .then(() => {
        message.success('Title updated');
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  // update url
  // eslint-disable-next-line no-shadow
  const onLinkBlur = (value) => (event) => {
    const { id, type } = value;

    const urlRegex = REGEX.WEB_URL;
    const mailRegex = REGEX.EMAIL;

    const { value: val = '' } = event?.target;

    const isUrlValid = urlRegex.test(val);
    const isMailValid = mailRegex.test(val);
    const isContentValid = val?.length !== 0;

    if (type === 'EMAIL' && !isMailValid) {
      setErrorId(id);
      setErrorMessage('Please enter valid email');
    } else if (type === 'URL' && !isUrlValid) {
      setErrorId(id);
      setErrorMessage('Please enter valid url');
    } else if (type === 'CONTENT' && !isContentValid) {
      setErrorId(id);
      setErrorMessage('Content must not be empty');
    } else {
      setErrorId('');
      setErrorMessage('');

      client
        .mutate({
          mutation: MODIFY_LINK,
          variables: {
            id: id,
            value: val
          },
          update: (cache, { data }) => {
            // eslint-disable-next-line no-shadow
            const id = cache.identify(
              data && data.updateUserLink && data.updateUserLink.id
            );
            cache.modify({
              fields: {
                title(existingTitleRefs, { readField }) {
                  return [...existingTitleRefs, readField(id)];
                }
              }
            });
          },
          optimisticResponse: {
            __typename: 'Mutation',
            updateUserLink: {
              __typename: 'UserLink',
              id: id,
              value: event.target.value
            }
          }
        })
        .then(() => {
          message.success('Link updated');
        })
        .catch((err) => {
          message.error(err.message);
        });
    }
  };

  // link active - deactive
  function onLinkChange(props) {
    client
      .mutate({
        mutation: MODIFY_LINK,
        variables: {
          id: props.id,
          isActive: !props.isActive
        },
        update: (cache, { data }) => {
          const id = cache.identify(
            data && data.updateUserLink && data.updateUserLink.id
          );
          cache.modify({
            fields: {
              title(existingTitleRefs, { readField }) {
                return [...existingTitleRefs, readField(id)];
              }
            }
          });
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateUserLink: {
            __typename: 'UserLink',
            id: props.id,
            isActive: !props.isActive
          }
        }
      })
      .then(() => {
        message.success('information updated');
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  // delete link
  function deleteLink(id) {
    client
      .mutate({
        mutation: DELETE_USERLINK,
        variables: {
          id
        },
        refetchQueries: [
          { query: GET_LINKS, variables: { custom: currentUser?.isCustom } },
          {
            query: GET_SCHEDULE_LINK,
            variables: { custom: currentUser?.isCustom }
          }
        ]
      })
      .then(() => {
        message.success('Link Removed');

        if (currentUser?.userLink) {
          dispatch({
            type: 'REMOVE_LINK',
            data: id
          });
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  }

  // Time schedule modal Handler
  const showTimeModalHandler = (isShow) => {
    setIsTimeModalVisible(isShow);
  };

  return (
    <Form key={value.id} className="links">
      <Card
        className="add-link-card"
        actions={
          remove && [
            <Row align="middle" justify="space-between">
              <Col className="action textDark">Delete this forever?</Col>
              <Col>
                <Button
                  className="btnLink"
                  type="link"
                  onClick={() => setRemove(!remove)}
                >
                  Cancel
                </Button>
                <Button
                  className="btnSecondary"
                  size="small"
                  shape="round"
                  onClick={() => deleteLink(value.id)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          ]
        }
      >
        <Row justify="start" align="middle">
          <Col className="draggable" span={2}>
            <DragHandle />
          </Col>
          <Col span={22}>
            <Row justify="space-between">
              <Col span={20}>
                {' '}
                <Form.Item onBlur={onTitleBlur(value.id)}>
                  <Paragraph className="title">{value.title || '-'}</Paragraph>
                </Form.Item>
              </Col>
              <Col>
                <>
                  {value && value.from ? (
                    <Tooltip title="click to update">
                      <div>
                        <Image
                          src={RoundArrow}
                          preview={false}
                          height={20}
                          width={20}
                          onClick={() => showTimeModalHandler(true)}
                          className="clock"
                          alt="icon"
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title={value.isActive ? 'Deactive' : 'Active'}>
                      <Switch
                        onChange={() => onLinkChange(value)}
                        defaultChecked={value.isActive}
                      />
                    </Tooltip>
                  )}
                </>
              </Col>
            </Row>
            <Row>
              <Col span={22}>
                <Form.Item onBlur={onLinkBlur(value)}>
                  <Paragraph className="description">{value.value}</Paragraph>
                  {value.id === errorId && (
                    <Alert
                      message={errorMessage}
                      closable
                      showIcon
                      type="error"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            {value.from && (
              <Row>
                <Col>
                  <Paragraph className="subTitle">Scheduled</Paragraph>
                  <Row>
                    <Col className="dateValue mr-10">
                      <p>
                        {getTime(value.from)} - {getTime(value.to)}
                      </p>
                    </Col>
                    <Col>
                      {value && (
                        <Tooltip title="click to update">
                          <div>
                            <img
                              src={RoundArrow}
                              preview={false}
                              onClick={() => showTimeModalHandler(true)}
                              className="card-clock"
                              alt="icon"
                            />
                          </div>
                        </Tooltip>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            <Row gutter={8} className="mt-12">
              <Col span={12}>
                <AddLinkModal
                  edit={value.id}
                  addLinkModalHandler={addLinkModalHandler}
                  isModalVisible={isModalVisible}
                  setRemove={setRemove}
                />
              </Col>
              <Col span={12}>
                <Button
                  block
                  type="danger"
                  size="small"
                  shape="round"
                  onClick={() => setRemove(!remove)}
                >
                  Delete
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* </Card> */}
        {/* only render time modal, if value.from is exist */}
        {value?.from && (
          <TimeScheduleModal
            linkId={value?.id}
            fromVal={value?.from}
            toVal={value?.to}
            isTimeModalVisible={isTimeModalVisible}
            showTimeModalHandler={showTimeModalHandler}
          />
        )}
      </Card>
    </Form>
  );
});

export default SortableItem;
