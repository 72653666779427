import React from 'react';
import { Row, Col, Layout } from 'antd';
import Phone from '../../components/Phone';
import ComingSoon from '../../components/ComingSoon';
import HelpButton from '../../components/HelpButton';

const { Content } = Layout;
const Setting = () => {
  return (
    <Content className="content">
      <Row justify="space-between" align="middle" className="links-Wrapper">
        <Col order={1} flex="auto" className="mt-36">
          <Row justify="center" align="middle">
            <Col>
              <ComingSoon />
            </Col>
          </Row>
        </Col>
        <Col className="phoneContainer" order={2}>
          <Row justify="center" align="middle">
            <Col className="device-wrapper">
              <Phone />
            </Col>
          </Row>
        </Col>
      </Row>
      <HelpButton />
    </Content>
  );
};
export default Setting;
