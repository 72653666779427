import { Button as AntButton } from 'antd';
import { React } from 'react';
import './styles.less';

const Button = ({
  children,
  onClick,
  className,
  type,
  icon,
  shape,
  size,
  style,
  loading,
  block,
  htmlType = 'submit',
  ...rest
}) => {
  return (
    <AntButton
      htmlType={htmlType}
      block={block}
      loading={loading}
      className={className}
      onClick={onClick}
      type={type}
      icon={icon}
      shape={shape}
      size={size}
      style={style}
      {...rest}
    >
      {children}
    </AntButton>
  );
};
export default Button;
