import { gql } from '@apollo/client';

const GET_LINKS = gql`
  query fetchLinks($custom: Boolean!) {
    userLinkList {
      id
      title
      value
      type
      from
      to
      position
      isActive
      user {
        isCustom
      }
      customAppearance @include(if: $custom) {
        button {
          id
          buttonStyle
        }
        font {
          id
          fontStyle
        }
      }
    }
  }
`;

const GET_THEMES = gql`
  {
    themeList {
      id
      title
      backgroundStyle
      buttonStyle
      fontStyle
      image
      planId
      isActive
    }
  }
`;

const GET_BUTTONS = gql`
  {
    buttonList {
      id
      buttonStyle
    }
  }
`;

const GET_BACKGROUND = gql`
  {
    backgroundList {
      id
      backgroundStyle
      backgroundImage
    }
  }
`;
const GET_FONTS = gql`
  {
    fontList {
      id
      fontStyle
    }
  }
`;

const GET_SHARABLE_LINK = gql`
  query getShareAbleLink($username: String!) {
    findShareableLink(data: { username: $username }) {
      id
      username
      profileImage
      isCustom
      userLink {
        id
        title
        value
        type
        from
        to
        isActive
        customAppearance {
          button {
            id
            buttonStyle
          }
          font {
            id
            fontStyle
          }
        }
      }
      theme {
        title
        backgroundStyle
        buttonStyle
        fontStyle
      }
      page {
        id
        backgroundId
        isActive
        background {
          id
          backgroundStyle
          backgroundImage
        }
      }
    }
  }
`;

const GET_LINK = gql`
  query getSingleLink($id: ID!) {
    findUserLinkList(data: { id: $id }) {
      id
      title
      value
      type
      from
      to
      position
      isActive
    }
  }
`;

const GET_SCHEDULE_LINK = gql`
  query getScheduelLinks($custom: Boolean!) {
    userLinkListDisplaysScheduling {
      id
      title
      value
      type
      from
      to
      position
      isActive
      user {
        isCustom
      }
      customAppearance @include(if: $custom) {
        button {
          id
          buttonStyle
        }
        font {
          id
          fontStyle
        }
      }
    }
  }
`;

const GET_TYPES = gql`
  query {
    typeList {
      id
      key
      value
    }
  }
`;
export default GET_LINKS;
export {
  GET_THEMES,
  GET_SHARABLE_LINK,
  GET_LINK,
  GET_BUTTONS,
  GET_BACKGROUND,
  GET_FONTS,
  GET_SCHEDULE_LINK,
  GET_TYPES
};
