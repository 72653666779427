import React, { useContext } from 'react';
import { Layout, Result, Row, Typography, Col } from 'antd';
import { useHistory } from 'react-router-dom';
import Card from '../../components/Card/card';
import { Logo } from '../../assets/index';
import Button from '../../components/Button/Button';
import { ROUTES } from '../../common/constant';
import { AppContext } from '../../AppContext';

const { Paragraph } = Typography;
const { Content } = Layout;

function Success() {
  const { dispatch } = useContext(AppContext);
  const history = useHistory();
  const continueBtnHandler = () => {
    dispatch({ type: 'LOGOUT' });
    history.push(ROUTES.LOGIN);
  };

  return (
    <Content className="login-container">
      <Row align="middle" justify="center" className="mb-20">
        <Col>
          <img src={Logo} alt="" logo />
        </Col>
        <Col>
          <Paragraph className="logoText">1Lynk</Paragraph>
        </Col>
      </Row>
      <Row justify="center" gutter={{ xs: 3, sm: 16, md: 24, lg: 32 }}>
        <Col className="gutter-row">
          <Card className="link-card">
            <Result status="success" title="Details Update Successfully!" />
            <Button type="primary" onClick={continueBtnHandler}>
              Continue
            </Button>
          </Card>
        </Col>
      </Row>
    </Content>
  );
}
export default Success;
