/* ROUTES */

export const ROUTES = {
  // user routes
  MAIN: '/',
  LOGOUT: '/logout',
  LOGIN: '/login',
  SIGNUP: '/register',
  RESET: '/forgot-password',
  CHANGE: '/reset-password',
  VERIFY: '/verify',
  SHAREABLE_LINK: '/:username',
  SUCCESS: '/user/details-updated',
  PLANS_PRICING: '/plans',
  ADMIN: '/admin',
  ADMIN_DASHBOARD: '/dashboard',
  LINKS: '/admin/links',
  APPEARANCE: '/admin/appearance',
  ANALYSIS: '/admin/analysis',
  SETTINGS: '/admin/settings',
  USER_PROFILE: '/admin/profile'
};

/* Authentication */
export const USER = 'USER';
export const TOKEN = 'jwtToken';

/* File Size Setup */
export const MAX_FILE_SIZE = 5; // size in mb
export const MAX_FILE_SIZE_ERROR_MESSAGE = `File size must be less than ${MAX_FILE_SIZE} MB`;
export const FILE_SIZE_UNIT = 1000000; // covering for bytes into given file size in mb.

/* Roles */
export const ROLES = {
  SUPER_ADMIN: 'Super admin',
  ADMIN: 'Admin'
};
export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL: /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-z0-9_-]+(\.[a-z]+)+((\/)[a-z0-9_#]+)*(\/\w+\?[a-z0-9_]+=[a-z0-9_]+(&[a-z0-9_]+=[a-z0-9_]+)*)?$/,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL: /^[a-z0-9.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/
};
