import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Col, Form, Layout, message, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ADD_USER from './graphql/Mutation';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/card';
import Input from '../../components/Input/input';
import { lynkLogo } from '../../assets/index';
import '../../styles/pages/auth.less';
import { ROUTES } from '../../common/constant';

const { Content } = Layout;
const { Title, Text } = Typography;

function Registration() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-undef
  const idToken = localStorage.getItem('jwtToken');

  const layout = {
    labelCol: {
      span: 5
    },
    wrapperCol: {
      span: 17
    }
  };

  useEffect(() => {
    if (idToken) {
      history.push(ROUTES.ADMIN);
    } else {
      history.push(ROUTES.SIGNUP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [addUser] = useMutation(ADD_USER, {
    onCompleted: () => {
      history.push(`/login`);
    }
  });
  const onFinish = (values) => {
    const pattern = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])'
    );
    if (
      !(pattern.test(values.password) && pattern.test(values.confirmPassword))
    ) {
      message.error(
        'Must have at least one lowercase letter, one uppercase letter, one digit, one special character'
      );
    } else if (values.password !== values.confirmPassword) {
      message.error('Password and confirm password are not matching');
    } else {
      setLoading(true);
      addUser({
        variables: {
          ...values
        }
      })
        .then(() => {
          setLoading(false);
          message.success('Check your Mail for further process');
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = () => {
    message.error('Please fill all the fields');
  };
  return (
    <Row align="middle" justify="center" className="main-container">
      <Col xs={24} lg={12}>
        <Content className="login-container">
          <Row justify="center" gutter={{ xs: 3, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row">
              <Row align="middle" justify="center" className="mb-20">
                <Col>
                  <img className="lynk-logo" src={lynkLogo} alt="logo" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Col>

      <Col xs={24} lg={12} className="vertical-divider">
        <Content className="login-container">
          <Row justify="start" gutter={{ xs: 3, sm: 16, md: 24, lg: 32 }}>
            <Col className="gutter-row">
              <Card className="link-card">
                <Row className="mb-20">
                  <Col className="text-start">
                    <Title level={2} className="m-0 login-title">
                      Create your 1Lynk account,
                    </Title>
                    <Text className="m-0 login-text">It's Free</Text>
                  </Col>
                </Row>
                <Form
                  name="basic"
                  initialValues={{}}
                  onFinish={onFinish}
                  {...layout}
                  onFinishFailed={onFinishFailed}
                >
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your username!'
                      }
                    ]}
                  >
                    <Input
                      addonBefore="Username"
                      suffix={<UserOutlined className="color-grey" />}
                      placeholder="Username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email!'
                      }
                    ]}
                  >
                    <Input
                      type="email"
                      addonBefore="Email"
                      suffix={<MailOutlined className="color-grey" />}
                      placeholder="E-mail"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!'
                      }
                    ]}
                  >
                    <Input
                      addonBefore="Password"
                      type="password"
                      suffix={<LockOutlined className="color-grey" />}
                      placeholder="Password"
                    />
                  </Form.Item>
                  <Form.Item
                    name="confirmPassword"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your confirm password!'
                      }
                    ]}
                  >
                    <Input
                      addonBefore="Confirm"
                      type="password"
                      suffix={<LockOutlined className="color-grey" />}
                      placeholder="Confirm Password"
                    />
                  </Form.Item>
                  <Row>
                    <div className="gx-fs-sm">
                      Note:- Password must have at least 1 lowercase letter 1
                      uppercase letter 1 digit and 1 special character.
                    </div>
                  </Row>
                  <Row justify="space-between" align="middle" className="mt-20">
                    <Button
                      className="btnPrimary"
                      type="primary"
                      loading={loading}
                      htmlType="submit"
                      size="small"
                      shape="round"
                    >
                      Sign up
                    </Button>
                    <Link to="/login">Already Have an account ?</Link>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
      </Col>
    </Row>
  );
}
export default Registration;
